import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaChevronRight } from 'react-icons/fa';
import { getMaterial, getMaterials, resetMaterial } from '../actions/Materials';
import MaterialIcon from './ManageContent/MaterialIcon';
import Loading from './Loading';
import { SHEET_KEY_MATERIAL } from '../constants/sideSheets';
import { setSideSheet } from '../actions/SideSheets';

const MyMaterials = ({
  listConfig,
  className = '',
  title = 'My Resources',
  selectable,
  onSelect
}) => {
  const dispatch = useDispatch();
  // Redux
  const materials = useSelector((state) => state.materials);

  const retrieveMaterials = useCallback(() => {
    dispatch(getMaterials(listConfig));
  }, [listConfig]);

  const openMaterial = (material) => {
    dispatch(getMaterial(material?.id));
    dispatch(setSideSheet({
      key: SHEET_KEY_MATERIAL,
      className: 'MaterialSideSheet',
      callbackPrimaryAction: () => {
        dispatch(resetMaterial());
        retrieveMaterials();
      }
    }));
  };

  useEffect(() => {
    retrieveMaterials();
  }, [retrieveMaterials]);

  if (!materials.fetched && !materials.list) {
    return (
      <Loading
        className='position-relative'
        style={{ background: '#121212' }}
        text="Loading Materials..."
      />
    );
  }

  return (
    <div className={className}>
      <div
        className="mt-3 pb-2 h5 mb-2 d-flex align-items-center justify-content-between"
      >
        <div className='d-flex align-items-center'>
          <span
            className='BoxText'
          >
            {title}
          </span>

          <span className='h6 mb-0 mx-2'>
            <span className='badge alert-warning alert py-1 px-2 m-0'>
              drafts
            </span>
          </span>

        </div>
      </div>

      {(Array.isArray(materials?.list) && materials.list.length > 0) && (
        <div>
          {materials.list.map((material) => (
            <div
              className='bg-white mb-2 border rounded p-2 d-flex align-items-center justify-content-between'
              key={material?.id}
            >

              <div
                className='d-flex align-items-center'
                style={{
                  maxWidth: '78%'
                }}
              >
                <MaterialIcon
                  className='text-ships-officer'
                  promptType={material?.promptType}
                  fileUploadFlow={material?.fileUploadFlow}
                />
                {selectable && (
                  <span
                    className='text-ships-officer ml-2'
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {material?.title}
                  </span>
                )}

                {!selectable && (
                  <button
                    className='btn-link text-ships-officer ml-2'
                    type='button'
                    onClick={() => {
                      openMaterial(material);
                    }}
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {material?.title}
                  </button>
                )}
              </div>
              <div className='d-flex align-items-center'>
                {selectable && (
                  <button
                    className='btn btn-sm btn-outline-primary'
                    type='button'
                    aria-label='View'
                    onClick={() => {
                      onSelect(material);
                    }}
                  >
                    Select
                  </button>
                )}

                {!selectable && (
                  <button
                    className='btn btn-sm btn-white'
                    type='button'
                    aria-label='View'
                    onClick={() => {
                      openMaterial(material);
                    }}
                  >
                    <FaChevronRight />
                  </button>
                )}
              </div>

            </div>
          ))}
        </div>
      )}

    </div>
  );
};

export default MyMaterials;
