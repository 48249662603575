import React, { useMemo, useState } from 'react';
import SelectMenu from '@apprentage/components/dist/components/SelectMenu';
import {
  reactSelectDefaultValue,
  reactSelectOptionsFromArray
} from '@apprentage/utils';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFlags } from 'flagsmith/react';
import { useHistory } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import {
  FLAG_LOGBOOKS_VELA_READONLY,
  NOTICE_LOGBOOKS_READONLY_TITLE,
  NOTICE_MAINTENANCE_MODE_BODY
} from '../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../actions/FeatureFlags';
import { createLogbook } from '../../services/logbooks';
import { LOGBOOK } from '../../constants/routes';
import heapAnalytics from '../../utils/heapAnalytics';
import './style.css';

const LogbookForm = ({ availableApprenticeshipsList }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const flags = useFlags([FLAG_LOGBOOKS_VELA_READONLY]);
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || '';
  // User
  const userId = currentUser?.id || '';
  const locationId = currentUser?.locationId || '';
  // Local State
  const [apprenticeshipId, setApprenticeshipId] = useState('');
  // Apprenticeships
  const apprenticeshipsOptions = reactSelectOptionsFromArray(
    availableApprenticeshipsList,
    'title'
  );
  const apprenticeshipsDefaultValue = reactSelectDefaultValue(
    apprenticeshipId,
    apprenticeshipsOptions
  );

  const canCreateNewLogbook = useMemo(() => {
    if (!availableApprenticeshipsList) {
      return false;
    }

    if (
      Array.isArray(availableApprenticeshipsList)
      && availableApprenticeshipsList.length === 0
    ) {
      return false;
    }

    return true;
  }, [availableApprenticeshipsList]);

  const onClickNewLogbook = () => {
    if (
      flags?.logbooks_vela_readonly?.enabled
      && flags?.logbooks_vela_readonly?.value
    ) {
      dispatch(
        showFeatureFlagNoticeModal({
          modalTitle: NOTICE_LOGBOOKS_READONLY_TITLE,
          modalBody: NOTICE_MAINTENANCE_MODE_BODY
          // preventClose: true
        })
      );
      return;
    }

    if (!apprenticeshipId) {
      toast.error('Training Program is required.');
      return;
    }

    const newLogbook = {
      apprenticeshipId,
      locationId,
      employerId: locationId, // TODO Deprecate
      userId,
      orgId
    };

    heapAnalytics.track('Logbook - Create - Preflight', {
      ...newLogbook
    });

    createLogbook({
      ...newLogbook,
      userName: currentUser?.name // TODO Deprecate
    })
      .then((logbookId) => {
        toast.success('Logbook created!');
        heapAnalytics.track('Logbook - Create - Success', {
          logbookId,
          userId
        });

        history.push(`${LOGBOOK}?logbookId=${logbookId}`);
      })
      .catch((error) => {
        console.error(error);

        heapAnalytics.track('Error - Logbook - Create', {
          error: JSON.stringify(error),
          apprenticeshipId,
          userId,
          locationId,
          orgId
        });
      });
  };

  return (
    <div className="LogbookForm theme-dark rounded mt-4 p-3 text-center">
      <div className="list-group-item">
        {canCreateNewLogbook && (
          <div className="mt-3">
            <h6 className="font-weight-bold">Training Program</h6>

            <span className="d-inline">
              <SelectMenu
                className="zIndex-1000 mb-3"
                id="apprenticeshipId"
                name="apprenticeshipId"
                placeholder="Choose Training Program"
                options={apprenticeshipsOptions}
                defaultValue={apprenticeshipsDefaultValue}
                onChange={({ value }) => {
                  setApprenticeshipId(value);
                }}
              />
            </span>
          </div>
        )}
      </div>

      <button
        disabled={!canCreateNewLogbook}
        className="btn btn-md btn-white font-weight-bold mt-4"
        onClick={onClickNewLogbook}
        type="button"
      >
        <span className="d-flex align-items-center">
          <FaPlus />
          <span className="ml-2">New Logbook</span>
        </span>
      </button>
    </div>
  );
};

export default LogbookForm;
