import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetCurrentModal } from '../../../actions/Modals';
import { LOGBOOKS } from '../../../constants/routes';
import Modal from '../../Modal';
import RecordForm from '../../RecordForm';

const ManageRecord = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Modal Data
  const modalData = currentModal?.data || {};
  const record = modalData?.record || null;

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const onErrorCallback = () => {
    handleClose();
    history.replace(LOGBOOKS);
  };

  if (record && !apprenticeship?.id) {
    return null;
  }

  if (currentModal && !currentModal.visible) {
    return null;
  }

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal.visible}
      close={handleClose}
      pos="top"
      theme="dark"
    >
      <RecordForm
        onSuccessCallback={handleClose}
        onCancelCallback={handleClose}
        onErrorCallback={onErrorCallback}
      />
    </Modal>
  );
};

export default ManageRecord;
