import React from 'react';
import { useSelector } from 'react-redux';
import Transcript from '../../../LogbookRecord/Transcript';
import CompetenciesCollapse from '../../../LogbookRecord/CompetenciesCollapse';

const TranscriptCell = ({ row, value }) => {
  // Redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Competencies
  const competencyIds = row?.original?.competencyIds;
  const competencies = apprenticeship?.competencies;

  return (
    <div>
      <Transcript text={value} />

      <CompetenciesCollapse
        competencyIds={competencyIds}
        competencies={competencies}
      />
    </div>
  );
};

export default TranscriptCell;
