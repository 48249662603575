import { toast } from 'react-toastify';
import isLetter from '../utils/isLetter';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  getSupabaseLogbookHours,
  updateSupabaseEntry
} from './supabaseProxy';

const table = 'logbooks';

/**
 * @param {Array} ids
 * @param {String} orgId
 */
export const fetchLogbookHours = ({
  orgId, // required
  logbookIds,
  locationId,
  apprenticeshipId,
  gteDate = undefined,
  lteDate = undefined,
  showCompHours = false

}) => {
  return new Promise((resolve, reject) => {
    const config = {
      orgId,
      logbookIds,
      ...(locationId ? { locationId } : {}),
      ...(apprenticeshipId ? { apprenticeshipId } : {}),
      ...(gteDate ? { gteDate } : {}),
      ...(lteDate ? { lteDate } : {}),
      ...(showCompHours ? { showCompHours } : {})
    };

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    getSupabaseLogbookHours(config, table).then(async (response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`fetch ${table}`, error);
      reject(error);
    });
  });
};

/**
 * @param {Array} ids
 * @param {String} orgId
 */
export const fetchLogbooks = ({
  ids = [],
  userId = undefined,
  apprenticeshipId = undefined,
  employerId = undefined,
  orgId = undefined
}) => {
  return new Promise((resolve, reject) => {
    const config = {};

    if (apprenticeshipId !== undefined) {
      config['f.apprenticeshipId[eq]'] = apprenticeshipId;
    }

    if (employerId !== undefined) {
      config['f.employerId[eq]'] = employerId;
    }

    if (userId !== undefined) {
      config['f.userId[eq]'] = userId;
    }

    if (orgId !== undefined) {
      config['f.orgId[eq]'] = orgId;
    }

    if (ids.length) {
      config.ids = ids.join(',');
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    fetchSupabaseEntries(config, table).then(async ({ items }) => {
      const list = items.length ? items : null;

      resolve(list);
    }).catch((error) => {
      console.error(`fetch ${table}`, error);
      reject(error);
    });
  });
};

/**
 *
 * @param {string} id
 */
export const fetchLogbook = (id) => {
  return new Promise((resolve, reject) => {
    fetchSupabaseEntry({ table, id })
      .then((response) => {
        const logbook = response || null;

        resolve(logbook);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const createLogbook = (data) => {
  return new Promise((resolve) => {
    createSupabaseEntry({ data, table })
      .then((response) => {
        const id = response?.id || null;

        resolve(id);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`create ${table}`, error);
      });
  });
};

/**
 *
 * @param {string} id
 * @param {object} data
 */
export const updateLogbook = (data, id) => {
  return new Promise((resolve) => {
    updateSupabaseEntry({
      table,
      data,
      id
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`update ${table}`, error);
      });
  });
};

/**
 *
 * @param {string} id
 * @param {object} data
 */
export const deleteLogbook = (id) => {
  return new Promise((resolve) => {
    deleteSupabaseEntry({
      data: { id },
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error(`delete ${table}`, error);
    });
  });
};

export const classifyInput = ({
  competencies = {},
  classifications = {},
  input = undefined
  // input = 'Human Resources, Electrical Component Repair.',
  // input = 'Tool Maintenance, Electrical Component Repair,',
}) => {
  // Duties
  let competencyIds = [];
  const competencyKeys = Object.keys(competencies);
  const classificationKeys = Object.keys(classifications);

  if (competencyKeys.length === 0 || classificationKeys.length === 0 || !input) {
    console.log('one or more undefined', competencies, classifications, input);
    return {
      classificationIds: [],
      competencyIds: []
    };
  }

  let identifiedKeywords = input.trim();
  // Remove any whitespace before or after a `,`
  identifiedKeywords = identifiedKeywords.replace(/\s*,\s*/ig, ',');
  // Remove trailing comma
  if (!isLetter(identifiedKeywords.slice(-1))) {
    // if (identifiedKeywords.slice(-1) === ',' || identifiedKeywords.slice(-1) === '.') {
    identifiedKeywords = identifiedKeywords.slice(0, -1);
  }
  // Convert string to array
  const keywords = identifiedKeywords.split(',');
  // Corresponding eNum key from value
  const keywordIds = classificationKeys.filter((key) => keywords.includes(classifications[key]));

  Object.values(competencies).forEach((comp, i) => {
    let classificationIds = [];
    const ids = comp?.classificationIds ? Object.keys(comp.classificationIds) : [];

    if (ids.length !== 0) {
      classificationIds = ids.filter((id) => keywordIds.includes(id));
    }

    competencyIds = [
      ...competencyIds,
      ...(classificationIds.length ? [competencyKeys[i]] : [])
    ];
  });

  const data = {
    classificationIds: keywordIds,
    competencyIds
  };

  console.log('- - - ALL - - -');
  console.log('classifications', classifications);
  console.log('competencies', competencies);
  console.log('- - - SELECTED - - -');
  console.log('competencyIds', data.competencyIds);
  console.log('classificationIds', data.classificationIds);
  console.log('competencies', data.competencyIds.map((id) => competencies[id].description));
  console.log('classifications', data.classificationIds.map((id) => classifications[id]));
  console.log('- - - ');

  return data;
};
