import {
  SET_APPRENTICESHIP, RESET_APPRENTICESHIP
} from '../../actions/types';
import apprenticeshipState from '../initialState/apprenticeshipState';

const initialState = {
  ...apprenticeshipState,
  user: null,
  fetched: false
};

export default function apprenticeship(state = initialState, action) {
  switch (action.type) {
    case SET_APPRENTICESHIP:
      return {
        ...state,
        ...action.apprenticeship,
        fetched: true
      };
    case RESET_APPRENTICESHIP:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
