import {
  SET_MATERIALS, RESET_MATERIALS
} from '../../actions/types';

const initialState = {
  list: null,
  fetched: false
};

export default function materials(state = initialState, action) {
  switch (action.type) {
    case SET_MATERIALS:
      return {
        ...state,
        list: action.list || null,
        pagination: action.pagination,
        fetched: true
      };
    case RESET_MATERIALS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
