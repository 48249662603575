import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { isLocalhost } from '../serviceWorker';
import { client, flattenItem } from './contentful';
import {
  PLATFORM_NAME,
  TURBINE_ADMIN_BCC_EMAIL, TURBINE_ADMIN_EMAIL
} from '../constants/globals';
import * as twApi from './tw-api';
import { dateTimestamp } from '../utils/date';
import isPublished from '../utils/isPublished';
import { GMAIL_SEND } from '../constants/api';
import { name } from '../../package.json';
import { VELA_MARKETING_SITE } from '../constants/urls';

const { navigator, localStorage } = window;

export const fetchOrg = ({
  orgId, slug, select, include
}) => {
  const config = {
    content_type: 'organization',
    include
  };

  if (orgId) {
    config['sys.id'] = orgId;
  } else if (slug) {
    config['fields.slug'] = slug;
  }

  if (select) {
    config.select = select.join(',');
  }

  return new Promise((resolve, reject) => {
    client.getEntries(config).then(({ items }) => {
      const organization = items[0] !== undefined ? flattenItem(items[0]) : null;

      resolve(organization);
    }).catch((error) => {
      console.error(error.message);
      reject(error);
    });
  });
};

export const setUserAppVersion = (appVersion) => {
  if (navigator.cookieEnabled) {
    Cookies.set(name, appVersion);
  }

  if (localStorage) {
    localStorage.setItem(name, appVersion);
  }
};

export const getUserAppVersion = () => {
  if (navigator.cookieEnabled) {
    return Cookies.get(name);
  }

  if (localStorage) {
    return localStorage.getItem(name);
  }

  return false;
};

export const setUserLoggedIn = () => {
  const newDate = dateTimestamp();

  if (navigator.cookieEnabled) {
    Cookies.set('upstream_user_logged_in', newDate);
  }

  if (localStorage) {
    localStorage.setItem('upstream_user_logged_in', newDate);
  }
};

export const getUserLoggedIn = () => {
  if (navigator.cookieEnabled) {
    return Cookies.get('upstream_user_logged_in');
  }

  if (localStorage) {
    return localStorage.getItem('upstream_user_logged_in');
  }

  return false;
};

export const rmUserLoggedIn = () => {
  if (navigator.cookieEnabled) {
    Cookies.remove('upstream_user_logged_in');
  }

  if (localStorage) {
    localStorage.removeItem('upstream_user_logged_in');
  }
};

export const usingLatestAppVersion = (appVersion) => {
  const versionFromBrowser = getUserAppVersion();

  if (appVersion === versionFromBrowser) {
    return true;
  }

  // setUserAppVersion(appVersion);

  return false;
};

/**
 * @summary Emails Turbine Admins
 * @param {string} subject // 'Billing Question: [orgName]
 * @param {string} message // message
 * @param {string} fromName // User requesting support
 * @param {string} replyTo // User requesting support's email
 * @returns {Promise<string>} emails, link
 */
export const notifyTurbineSupport = ({
  subject, message, replyTo, fromName
}) => {
  return new Promise((resolve, reject) => {
    const to = TURBINE_ADMIN_EMAIL;
    const emailParts = {
      to,
      from: {
        name: fromName
      },
      replyTo,
      bcc: TURBINE_ADMIN_BCC_EMAIL,
      subject,
      body: '',
      htmlBody: `
        <p>${PLATFORM_NAME} Admin,</p>
        <p>${message}</p>
        <br />
        <p>
          Best,
          <br />
          ${fromName}
        </p>
        <font color='#888888'>--</font>
        <br>
        Powered by <a href='${VELA_MARKETING_SITE}?track=sendEmail' target='_blank' title='${PLATFORM_NAME}'>${PLATFORM_NAME}</a> a Turbine Workforce company.
      `
    };

    return twApi.post(GMAIL_SEND, '', JSON.stringify(emailParts)).then((response) => {
      if (response && response.status === 200) {
        resolve();
      } else {
        toast.error(response.message); // ex: 'Something went wrong, try again'
        console.error(response.status); // ex: 'error_invitation_fail'

        reject({
          message: response.message,
          status: response.status
        });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
};

export const getStripeCustomerId = (organization) => {
  if (!isPublished(organization)) return null;

  const { integration } = organization;
  if (!integration) return null;

  let customerId = null;

  if (integration && integration.stripe && integration.stripe.customer_id) {
    if (integration.stripe.customer_id[isLocalhost ? 'test' : 'live']) {
      customerId = integration.stripe.customer_id[isLocalhost ? 'test' : 'live'];
    }
  }

  return customerId;
};
