import sysState from './sysState';

const organizationState = {
  name: '',
  enablePathway: false,
  enableNewEditor: false,
  orgLogo: '',
  type: '',
  welcomeMessage: '',
  locations: null, // [{}] ref TODO remove
  locationIds: null, // string[]
  slug: '',
  restrictRegister: false,
  integrateSlack: false,
  integration: null, // {} TODO remove from state, set specific keys
  billing: false,
  enableResources: false,
  enableUserGroups: false,
  enableCourseCatalog: true,
  integrateMicrosoftTeams: false,
  enableVelaMaterials: false,
  ...sysState
};

export default organizationState;
