export const SLASH = '/';
export const DASHBOARD = '/dashboard';
export const PROGRAMS = '/programs';
export const PROGRAM = '/program';
export const LOGBOOK = '/logbook';
export const LOGBOOKS = '/logbooks';
export const RECORDS = '/records';
export const MATERIAL = '/material';
export const MATERIALS = '/materials';

// AUTH
export const SSO = '/sso';
export const LOGIN = '/login';
export const LOGIN_ORG = '/login/:organizationSlug?';
