import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { LOGBOOKS } from '../constants/routes';

const LogbookNotFound = () => {
  toast.error('No Logbook found.', { autoClose: false });

  return (
    <div className='text-white mt-3 text-center'>
      <p>
        Logbook not found
      </p>
      <Link
        className='btn btn-sm btn-white'
        to={LOGBOOKS}
      >
        <span className='d-flex align-items-center'>
          <FaArrowCircleLeft />
          <span className='ml-2'>
            Logbooks
          </span>
        </span>
      </Link>
    </div>
  );
};

export default LogbookNotFound;
