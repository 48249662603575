import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLogbookRecords } from '../../../actions/LogbookRecords';
import Loading from '../../Loading';
import List from './List';
import './style.css';

const LogbookRecordsList = ({
  className = '',
  listConfig,
  handleClickEdit
}) => {
  const dispatch = useDispatch();
  // Redux
  const logbookRecords = useSelector((state) => state.logbookRecords);

  useEffect(() => {
    dispatch(getLogbookRecords(listConfig));
  }, [listConfig]);

  if (!logbookRecords.fetched && !logbookRecords.list) {
    return (
      <Loading text="Loading Logbook Records..." />
    );
  }

  return (
    <div className={`LogbookRecordsList ${className}`}>
      <List
        handleClickEdit={handleClickEdit}
        data={logbookRecords.list}
        pagination={logbookRecords.pagination}
      />
    </div>
  );
};

export default LogbookRecordsList;
