import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const PaginationSupabase = ({
  items,
  pagination, // { rangeTo, rangeFrom, total, limit }
  page,
  onClickNext,
  onClickPrev
}) => {
  if (!items || (items && !items.length) || !pagination) {
    return null;
  }
  const total = pagination?.total;
  const limit = pagination?.limit;
  const rangeTo = pagination?.rangeTo;
  const rangeFrom = pagination?.rangeFrom;
  const numPages = (total >= items.length) ? Math.ceil(total / limit) : 1;

  if (numPages === 1) {
    return null;
  }

  const nextDisabled = (rangeTo >= total);
  const prevDisabled = (page === 1);

  return (
    <div
      style={{
        position: 'sticky',
        bottom: 0,
        background: 'white'
      }}
      className="PaginationSupabase d-flex justify-content-end align-items-center py-3 border-top"
    >
      <div className='PaginationSupabase-range small mr-3'>
        {rangeFrom} - {rangeTo} of {total} items
      </div>
      <div className='mr-2'>
        <button
          className={`mr-2 btn btn-sm ${prevDisabled ? 'btn-outline-secondary' : 'btn-outline-primary'}`}
          tabIndex="-1"
          aria-disabled="true"
          onClick={onClickPrev}
          disabled={prevDisabled}
          type="button"
        >
          <span className='d-flex align-items-center'>
            <FaChevronLeft />
            <span className='ml-2'>
              Previous
            </span>
          </span>
        </button>

        <button
          className={`btn btn-sm ${nextDisabled ? 'btn-outline-secondary' : 'btn-outline-primary'}`}
          tabIndex="-1"
          aria-disabled="true"
          onClick={onClickNext}
          disabled={nextDisabled}
          type="button"
        >
          <span className='d-flex align-items-center'>
            <span className='mr-2'>
              Next
            </span>
            <FaChevronRight />
          </span>
        </button>
      </div>
    </div>
  );
};

export default PaginationSupabase;
