import { SET_CURRENT_USER } from '../../actions/types';
import { getMembershipReadable } from '../../services/currentUser';
import currentUserState from '../initialState/currentUserState';

const initialState = {
  ...currentUserState,
  membershipReadable: null,
  fetched: false
};

export default function currentUser(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER: {
      const membership = action?.currentUser?.membership || null;

      return {
        ...state,
        ...action.currentUser,
        membershipReadable: membership ? getMembershipReadable(membership) : null,
        fetched: true
      };
    }
    default:
      return state;
  }
}
