import { fetchLogbook, fetchLogbooks, fetchLogbookHours } from '../services/logbooks';
import heapAnalytics from '../utils/heapAnalytics';
import { openReportIssueModal } from './Modals';
import {
  SET_LOGBOOKS,
  RESET_LOGBOOKS,
  RESET_LOGBOOK,
  SET_LOGBOOK,
  SET_LOGBOOK_HOURS,
  RESET_LOGBOOK_HOURS
} from './types';

export const errorLogbookNotFound = ({
  logbookId,
  orgId,
  orgName,
  userId,
  userName,
  userEmail,
  userLocationId
}) => {
  return (dispatch) => {
    heapAnalytics.track('Error - Logbook not found', {
      logbookId,
      orgId,
      userId,
      locationId: userLocationId
    });

    const message = `I received an error that my Logbook can't be found.

(ID: ${logbookId})
(HREF: ${window.location.href})`;

    dispatch(openReportIssueModal({
      fromName: userName,
      replyTo: userEmail,
      orgName,
      message
    }));
  };
};

export const getLogbookHours = ({
  orgId,
  logbookId
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchLogbookHours({
        orgId,
        logbookIds: [logbookId],
        showCompHours: true
      }).then((response) => {
        const logbookHours = response?.items ? response?.items[0] : null;

        dispatch({
          type: SET_LOGBOOK_HOURS,
          logbookHours
        });

        resolve(logbookHours);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getLogbooks = ({
  userId,
  orgId,
  employerId,
  apprenticeshipId
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchLogbooks({
        orgId,
        ...(apprenticeshipId ? { apprenticeshipId } : {}),
        ...(employerId ? { employerId } : {}),
        ...(userId ? { userId } : {})
      })
        .then((list) => {
          dispatch({
            type: SET_LOGBOOKS,
            list
          });

          resolve(list);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getLogbook = ({
  id,
  orgId,
  logbookHours = false,
  logbookCompHours = false
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (id && orgId) {
        // TODO pass orgId to fetchLogbook
        fetchLogbook(id)
          .then((response) => {
            if (!response) {
              reject(response);
            }

            const logbook = { ...response, records: null };

            if (logbookHours) {
              dispatch(getLogbookHours({
                logbookId: id,
                orgId,
                ...(logbookCompHours ? { logbookCompHours } : {})
              }));
            }

            dispatch({
              type: SET_LOGBOOK,
              logbook
            });

            resolve(logbook);
          })
          .catch((error) => {
            console.error('getLogbook', error);
            reject(error);
          });
        return;
      }

      reject(new Error('getLogbook, missing params'));
    });
  };
};

export const resetLogbook = () => {
  return (dispatch) => {
    dispatch({ type: RESET_LOGBOOK });
    dispatch({ type: RESET_LOGBOOK_HOURS });
  };
};

export const resetLogbooks = () => {
  return (dispatch) => {
    dispatch({ type: RESET_LOGBOOKS });
  };
};
