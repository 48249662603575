import { toast } from 'react-toastify';
import { ICON_CITY, ICON_MAP_PIN } from '@apprentage/constants';
import { client, flattenItem, flattenItems } from './contentful';

export const locationTerminology = ({ orgType }) => {
  return {
    text: orgType !== 'workforce' ? 'Employer' : 'Location',
    icon: orgType !== 'workforce' ? ICON_CITY : ICON_MAP_PIN
  };
};

export const generateLocationsHash = (locations) => {
  const hash = {};

  if (!locations) return hash;

  locations.forEach((location) => {
    hash[location.id] = {
      ...location
    };
  });

  return hash;
};

export const fetchLocation = ({ locationId }) => {
  return new Promise((resolve, reject) => {
    return client
      .getEntry(locationId)
      .then((response) => {
        const orgLocation = response ? flattenItem(response) : null;

        resolve(orgLocation);
      })
      .catch((error) => {
        console.error(error.message);
        reject(error);
      });
  });
};

export const fetchLocations = ({
  orgId,
  locationIds,
  include = 1,
  excludeDefaultLocation = false
}) => {
  return new Promise((resolve, reject) => {
    const config = {
      content_type: 'location',
      'fields.orgId': orgId,
      order: 'fields.name',
      include
    };

    if (excludeDefaultLocation) {
      config['fields.defaultLocation[exists]'] = false;
    }

    if (locationIds && Array.isArray(locationIds) && locationIds.length) {
      config['sys.id[in]'] = locationIds.join(',');
    }

    client
      .getEntries(config)
      .then(({ items }) => {
        const locations = items.length ? flattenItems(items) : null;

        resolve(locations);
      })
      .catch((error) => {
        toast.error(error.message);
        reject(error);
      });
  });
};
