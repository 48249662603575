import React from 'react';
import {
  FaEllipsisH,
  // FaFolderPlus,
  FaPencilAlt,
  FaTimes
} from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { resetSideSheet } from '../../../actions/SideSheets';
// import { canCreateResources } from '../../../services/currentUser';
// import { SHEET_KEY_MATERIAL_ADD } from '../../../constants/sideSheets';

const DotMenu = ({ editMode, setEditMode }) => {
  const dispatch = useDispatch();

  return (
    <div className="d-flex justify-content-end align-items-center">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-white rounded bg-white py-1 border border-1"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          aria-label="Menu"
          title="Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />
          {!editMode && (
            <button
              className="dropdown-item border-bottom p-2"
              type='button'
              onClick={() => {
                setEditMode(true);
              }}
            >
              <span className='d-flex align-items-center'>
                <FaPencilAlt size={15} />
                <span className='ml-2 small'>
                  Edit
                </span>
              </span>
            </button>
          )}

          {/* {!editMode && canCreateResources(role, orgType) && (
            <button
              className="dropdown-item border-bottom p-2"
              type='button'
              onClick={() => {
                dispatch(setSideSheet({
                  key: SHEET_KEY_MATERIAL_ADD,
                  data: {
                    material,
                    moveToResources: true
                  }
                }));
              }}
            >
              <span className='d-flex align-items-center'>
                <FaFolderPlus size={15} />
                <span className='ml-2 small'>
                  Move to Resources
                </span>
              </span>
            </button>
          )} */}

          <button
            className="dropdown-item p-2"
            type='button'
            onClick={() => {
              dispatch(resetSideSheet());
            }}
          >
            <span className='d-flex align-items-center'>
              <FaTimes size={15} />
              <span className='ml-2 small'>
                Close
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DotMenu;
