import {
  RESET_MATERIAL,
  RESET_MATERIALS,
  // SET_ENTRY_ORG_ID,
  SET_MATERIAL,
  SET_MATERIALS
} from './types';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { fetchMaterial, fetchMaterials } from '../services/materials';
import { getResourcesByParentId } from './Resources';

const table = 'materials';

export const getMaterials = ({
  orgId,
  userId = null,
  isDraft = null,
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1,
  order = '-createdAt',
  title = ''
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchMaterials({
        orgId,
        userId,
        isDraft,
        page,
        limit,
        order,
        title
      })
        .then((response) => {
          const list = Array.isArray(response?.items) && response?.items.length > 0 ? response?.items : null;
          // ORG CHECK
          // dispatch({
          //   type: SET_ENTRY_ORG_ID,
          //   entryOrgId: list && list[0] ? list[0]?.orgId : null
          // });

          dispatch({
            type: SET_MATERIALS,
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });

          resolve(response?.items);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getMaterial = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchMaterial(id)
        .then((material) => {
          if (!material?.id) {
            reject(new Error('No material found (id)'));
            return;
          }

          // ORG CHECK
          // dispatch({
          //   type: SET_ENTRY_ORG_ID,
          //   entryOrgId: material?.orgId || null
          // });

          dispatch({
            type: SET_MATERIAL,
            material: material?.id ? { ...material, cdnFiles: null } : null
          });
          dispatch(getResourcesByParentId(material?.id, 'materials'));
          resolve(material);
        })
        .catch((error) => {
          console.error(`get ${table}`, error);
          reject(error);
        });
    });
  };
};

export const resetMaterial = () => {
  return (dispatch) => {
    dispatch({ type: RESET_MATERIAL });
  };
};

export const resetMaterials = () => {
  return (dispatch) => {
    dispatch({ type: RESET_MATERIALS });
  };
};
