export const defaultPrompts = [
  {
    text: 'Get 3 Question/Answer Pairs',
    type: 'questionAnswerPairs'
  },
  {
    text: 'Get Main Points & Observations',
    type: 'mainPointsObservations'
  },
  {
    text: 'Write a LinkedIn Post',
    type: 'linkedInPost'
  },
  {
    text: 'Draft an Email',
    type: 'email'
  }
];
