import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { ICON_TRIANGLE_BANG } from '@apprentage/constants';
import { AWS_USER_PREFIX } from '../../../constants/aws';
import { uploadFile } from '../../../services/aws';
import { TINYMCE_KEY } from '../../../constants/globals';
import { getResourcesByParentId } from '../../../actions/Resources';
import { coerceToResourceName, createResource } from '../../../services/resources';
import FileUploader from '../FileUploader';
import Modal from '../../Modal';
import Alert from '../../Alert';

const RenderTinyMceEditor = ({
  id,
  className = '',
  title,
  subtitle,
  maxCharCount = 50000,
  // onImageUpload,
  // onImagePreview,
  // onImageUploadBefore,
  defaultValue = '',
  contentId,
  contentType,
  editorRef,
  uploadPdfButton = false,
  embedMediaButton = false,
  uploadImgButton = false,
  height = 500,
  onChange,
  onExceedMaxChars,
  autoFocus = false,
  forcedRootBlock = 'p',
  skin = 'oxide-dark',
  contentCss = 'dark',
  required
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || '';
  // Current User

  // Local State
  const [chooseFileModal, setChooseFileModal] = useState(false);
  const [maxCharsExceeded, setMaxCharsExceeded] = useState(false);
  const [charCount, setCharCount] = useState(defaultValue.length || 0);

  const handleImageUploadBefore = (file, success, failure) => { // progress
    // docs have first arg as blob, but its an abstraction
    // https://stackoverflow.com/questions/41009605/tinymce-editor-with-react-cannot-access-local-files
    // https://www.tiny.cloud/docs/configure/file-image-upload/#file_picker_callback
    // uploadHandler is a function
    const blob = file.blob();
    console.log(blob);

    toast.info('Uploading image...');

    uploadFile({
      prefix: `${AWS_USER_PREFIX}/${orgId}`,
      file: blob,
      orgId
    }).then((response) => {
      const fileObj = {
        ...response // id, url
      };

      if (blob.name) {
        fileObj.name = coerceToResourceName({
          str: blob.name,
          fixCamelCase: true,
          fixHyphens: true,
          fixUnderScores: true
        });
      }
      if (blob.type) fileObj.type = blob.type;
      if (blob.size) fileObj.size = blob.size;

      toast.info('Optimizing image...');

      // Add image in editor
      success(fileObj.url);

      // ADD Resource
      const dataToCreate = {
        ...fileObj,
        orgId,
        userId: currentUser?.id,
        userName: currentUser?.name,
        type: fileObj.type,
        parentType: contentType,
        parentId: contentId
      };

      createResource(dataToCreate).then(() => {
        dispatch(getResourcesByParentId(contentId, contentType)).then(() => {
          toast.success('Image uploaded');
        });
      }).catch(() => {
        toast.error('Something went wrong');
      });
    }).catch((error) => {
      failure(`Image upload failed ${error}`);
      console.error(error);
    });
  };

  const getCharacterCount = () => {
    let content = '';
    let count = 0;
    if (editorRef && editorRef.current) {
      content = editorRef.current.getContent();

      if (content) {
        count = content.length;
      }
    }

    setCharCount(count);

    if (!maxCharsExceeded && count > maxCharCount) {
      setMaxCharsExceeded(true);

      if (onExceedMaxChars && typeof onExceedMaxChars === 'function') {
        onExceedMaxChars(true);
      }
    }

    if (maxCharsExceeded && count <= maxCharCount) {
      setMaxCharsExceeded(false);

      if (onExceedMaxChars && typeof onExceedMaxChars === 'function') {
        onExceedMaxChars(false);
      }
    }

    return charCount;
  };

  const handleInsertUploadedFile = (fileObj) => {
    const { url, name } = fileObj;

    if (editorRef && editorRef.current) {
      editorRef.current.insertContent(`
        <div>
          <p>Download/View: <a target="_blank" href="${url}">${name}</a></p>
          <iframe src="${url}" style="width: 100%;" width="100%" height="2200"></iframe>
        </div>
      `); // HTML
      setChooseFileModal(false);
    } else {
      setChooseFileModal(false);
    }

    // ADD Resource
    const dataToCreate = {
      ...fileObj,
      orgId,
      userId: currentUser?.id,
      userName: currentUser?.name,
      type: fileObj.type,
      parentType: contentType,
      parentId: contentId
    };

    createResource(dataToCreate).then(() => {
      dispatch(getResourcesByParentId(contentId, contentType)).then(() => {
        toast.success('File uploaded!');
      });
    }).catch(() => {
      toast.error('Something went wrong');
    });
  };

  let uploadPdfStr = '';
  let embedMediaStr = '';
  let embedImgStr = '';
  const plugins = [
    'autolink',
    'autoresize',
    // 'resize',
    'codesample',
    'link',
    'lists',
    // 'powerpaste',
    'table',
    'codesample'
    // 'quickbars'
  ];

  // Image Upload enabled
  if (uploadImgButton) {
    embedImgStr = 'image';
    plugins.push('image');
  }

  // Embed Media enabled
  if (embedMediaButton) {
    embedMediaStr = 'media';
    plugins.push('media');
  }

  // Upload PDF enabled
  if (uploadPdfButton) {
    uploadPdfStr = 'uploadPdf';
  }

  const init = {
    skin,
    content_css: contentCss,
    toolbar: `removeformat formatselect | bold italic strikethrough | alignleft aligncenter alignright | bullist numlist | link | ${embedImgStr} ${embedMediaStr} ${uploadPdfStr}`,
    // undo redo |
    toolbar_sticky: true,
    menubar: 'insert format table tools',
    contextmenu: false,
    statusbar: false,
    auto_focus: autoFocus,
    forced_root_block: forcedRootBlock,
    min_height: height,
    plugins,
    mobile: {
      // menubar: 'edit insert format',
      menubar: false,
      // toolbar: false,
      toolbar: `bold italic | bullist numlist | link | ${embedImgStr}`,
      selection_toolbar: false,
      inline: false
    },
    // link_assume_external_targets: true,
    link_class_list: [
      { title: 'None', value: '' },
      { title: 'Primary Button', value: 'btn btn-sm btn-primary' },
      { title: 'Secondary Button', value: 'btn btn-sm btn-secondary' },
      { title: 'Info Button', value: 'btn btn-sm btn-info' },
      { title: 'Warning Button', value: 'btn btn-sm btn-warning' },
      { title: 'Danger Button', value: 'btn btn-sm btn-danger' },
      { title: 'Link Button', value: 'btn btn-sm btn-link' }
    ],
    convert_newlines_to_brs: true,
    powerpaste_word_import: 'clean',
    powerpaste_html_import: 'clean',
    image_caption: true,
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    image_advtab: true,
    images_reuse_filename: true,
    images_upload_handler: handleImageUploadBefore,
    // quickbars_insert_toolbar: 'quicktable image media codesample',
    // quickbars_selection_toolbar: 'bold italic underline | formatselect | bullist numlist | blockquote quicklink',
    setup: (editor) => {
      if (uploadPdfButton) {
        editor.ui.registry.addButton('uploadPdf', {
          icon: 'browse',
          tooltip: 'Upload/Embed PDF',
          onAction(_) { // eslint-disable-line no-unused-vars
            setChooseFileModal(true);
          }
        });
      }
    }
  };

  return (
    <>
      <div
        id={id}
        className={`position-relative ${className}`}
        style={{
          zIndex: 0
        }}
      >
        {title && (
          <label className="mb-1 text-capitalize">
            <b>{title}:</b> {required && (<span className="text-danger">*</span>)}
          </label>
        )}
        {subtitle && (
          <div className="text-muted medium mb-2">
            {subtitle}
          </div>
        )}

        <Editor
          apiKey={TINYMCE_KEY}
          onInit={(evt, editor) => editorRef.current = editor} // eslint-disable-line no-return-assign
          initialValue={defaultValue}
          init={init}
          onChange={() => {
            getCharacterCount();

            if (onChange) {
              onChange();
            }
          }}
          onKeyUp={getCharacterCount}
        />

        <div className="d-flex justify-content-end mb-2">
          <div>
            <strong>{maxCharCount - charCount}</strong> characters remaining
          </div>
        </div>

        {charCount > maxCharCount && (
          <Alert
            type="danger"
            icon={`${ICON_TRIANGLE_BANG} text-danger`}
            className="m-0 mb-5 w-100 border"
          >
            <div>
              This field exceeds the maximum number of characters ({maxCharCount}) allowed.
            </div>
          </Alert>
        )}
      </div>

      <Modal
        visible={chooseFileModal}
        close={() => {
          setChooseFileModal(false);
        }}
      >
        <div>
          <label htmlFor="fileUpload">
            <b>Choose file</b>
          </label>
          <br />
          <FileUploader
            onFileUploadCallback={handleInsertUploadedFile}
            orgId={orgId}
          />
          <div className="mt-2 text-muted">
            Supported File types: <strong>.pdf</strong>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RenderTinyMceEditor;
