import React from 'react';
import { formatHours } from '@apprentage/utils';
import { mdySimple } from '../../utils/date';
import TimeBadge from '../TimeBadge';
import Transcript from './Transcript';
import CompetenciesCollapse from './CompetenciesCollapse';

const LogbookRecord = ({
  data,
  apprenticeshipCompetencies
}) => {
  return (
    <div className="ColoredBox rounded shadow position-relative p-3 mb-3">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className='BoxSubText'>
          {mdySimple(data?.date)}
        </div>

        <TimeBadge
          duration={formatHours(data?.hours ? data?.hours.toFixed(2) : 0)}
          badgeClassName=''
        />
      </div>
      <div
        className="BoxText text-center"
        style={{
          fontSize: '1rem'
        }}
      >
        <Transcript text={data?.transcript} />
      </div>

      <CompetenciesCollapse
        competencyIds={data?.competencyIds}
        competencies={apprenticeshipCompetencies}
      />
    </div>
  );
};

export default LogbookRecord;
