import { fetchUser } from '../services/users';
import {
  SET_USERS,
  SET_CURRENT_USER,
  SET_USER
} from './types';
import { fetchLocation } from '../services/locations';
import heapAnalytics from '../utils/heapAnalytics';

export const setUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER,
      user
    });
  };
};

export const resetUser = () => {
  return (dispatch) => {
    dispatch({
      type: SET_USER,
      user: null
    });
    dispatch({
      type: SET_CURRENT_USER,
      currentUser: null
    });
  };
};

export const getCurrentUser = ({ userId, select }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchUser({ userId, select }).then((response) => {
        if (response?.id) {
          dispatch(setCurrentUser(response)).then(() => {
            resolve(response);
          });
        } else {
          console.error('Current user missing.');
          reject(new Error('Current user missing.'));
        }
      });
    });
  };
};

export const setCurrentUser = (currentUser) => {
  const { locationId } = currentUser;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchLocation({ locationId }).then((userLocation) => {
        const dataToSave = {
          userLocation,
          currentUser
        };

        heapAnalytics.addUserProperties({
          locationId: userLocation?.id,
          locationName: userLocation?.name
        });

        dispatch({
          type: SET_CURRENT_USER,
          ...dataToSave
        });

        resolve(dataToSave);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const updateUserAttendanceAndUsers = ({
  userId,
  attendance
}) => {
  if (!userId || !attendance) return null;

  return (dispatch, getState) => {
    const { users } = getState();

    if (!users.list) return null;

    return new Promise((resolve) => {
      const updatedUsers = users.list.slice(0);
      const userIndex = updatedUsers.findIndex((u) => u.id === userId);

      if (userIndex === -1) return null;

      updatedUsers[userIndex] = {
        ...updatedUsers[userIndex],
        attendance
      };

      dispatch({
        type: SET_USERS,
        users: updatedUsers
      });

      resolve(updatedUsers);
    });
  };
};
