import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { canAccessTurbineAdmin, roleReadable } from '@apprentage/utils';
import { FaListAlt } from 'react-icons/fa';
import { withAuthorization } from '../../components/Session';
import { IMAGE_CARD_LOGBOOKS } from '../../constants/assets';
import { LOGBOOKS } from '../../constants/routes';
import { canAccessLogbook } from '../../services/currentUser';
import Loading from '../../components/Loading';
import DashboardCard from '../../components/DashboardCard';
import PersonActivityCard from '../../components/PersonActivityCard';
import { TURBINE_ADMIN } from '../../constants/urls';
import WorkflowCards from '../../components/WorkflowsCards';

const Dashboard = () => {
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeships = useSelector((state) => state.apprenticeships);
  // Current User
  const role = currentUser?.role || [];
  const apprenticeshipIds = currentUser?.apprenticeshipIds || null;
  const hasTrainingPrograms = apprenticeshipIds && Array.isArray(apprenticeships?.list) && apprenticeships?.list.length > 0;

  if (!currentUser?.id || !organization?.id) {
    return <Loading />;
  }

  return (
    <div style={{ maxWidth: '345px', margin: 'auto' }}>
      <div
        className="row justify-content-center mt-3"
      >
        <div className="col-12 mb-3">
          <PersonActivityCard
            name={currentUser?.name}
            personRole={currentUser?.title || roleReadable(currentUser?.role)}
            profilePicture={currentUser?.profilePicture || DEFAULT_PROFILE_PIC}
          />
        </div>
        <div
          className="col-12 mb-3"
        >
          {organization?.enableWorkforceSuite && (
            <>
              {canAccessLogbook(role) && !hasTrainingPrograms && (
                <DashboardCard
                  title="Logbooks"
                  subTitle="Record tasks for Training Programs."
                  onClickRoutePath={LOGBOOKS}
                  className='mb-3'
                  imgUrl={IMAGE_CARD_LOGBOOKS}
                  imgPosition="top"
                />
              )}

              {canAccessTurbineAdmin(role) && (
                <DashboardCard
                  href={TURBINE_ADMIN}
                  className='mb-3'
                  externalLink
                  title="Review Records"
                  subTitle="Review Trainee Logbook Records"
                  titleIconComponent={() => (
                    <FaListAlt size={25} className='mr-2 text-primary opacity-50' />
                  )}
                />
              )}
            </>
          )}

          <WorkflowCards showMyMaterialsLink />

        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(Dashboard);
