import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';
import { useFlagsmith, useFlagsmithLoading } from 'flagsmith/react';
import { FaListAlt, FaPlus } from 'react-icons/fa';
import { withAuthorization } from '../../components/Session';
import { LOGBOOK, RECORDS, SLASH } from '../../constants/routes';
import { getLogbooks, resetLogbooks } from '../../actions/Logbooks';
import { getApprenticeships, resetApprenticeships } from '../../actions/Apprenticeships';
import { canAccessLogbook } from '../../services/currentUser';
import LogbookForm from '../../components/LogbookForm';
import ProgramContainer from '../../components/Lists/ProgramsList/ProgramContainer';
import Loading from '../../components/Loading';
import { openReportIssueModal } from '../../actions/Modals';
import { serializeLogbooks } from '../../serializers/logbooks';

const Logbooks = () => {
  const dispatch = useDispatch();
  const { getFlags } = useFlagsmith();
  const { isLoading } = useFlagsmithLoading();
  // const velaInstructions = useRef(null);
  // const getStartedButton = useRef(null);
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeships = useSelector((state) => state.apprenticeships);
  const logbooks = useSelector((state) => state.logbooks);
  // Current User
  const userId = currentUser?.id || '';
  const role = currentUser?.role || [];
  const apprenticeshipIds = currentUser?.apprenticeshipIds || null;
  // Organization
  const orgId = organization?.id || '';
  const orgName = organization?.name || '';

  const availableApprenticeshipsList = useMemo(() => {
    let result = [];

    if (Array.isArray(apprenticeships.list) && apprenticeships.list.length > 0) {
      result = apprenticeships.list.slice(0);
    }

    if (Array.isArray(logbooks.list) && logbooks.list.length) {
      logbooks.list.forEach((logbook) => {
        result = result.filter((appren) => appren?.id !== logbook?.apprenticeshipId);
      });
    }

    return result;
  }, [logbooks.list, apprenticeships.list]);

  const serializedLogbooksList = useMemo(() => {
    return serializeLogbooks(logbooks.list, apprenticeships.list);
  }, [logbooks.list, apprenticeships.list]);

  useEffect(() => {
    if (!isLoading) {
      getFlags();
    }
  }, [getFlags, isLoading]);

  useEffect(() => {
    if (Array.isArray(apprenticeshipIds) && apprenticeshipIds.length > 0) {
      dispatch(getApprenticeships({ ids: apprenticeshipIds, orgId }));
    }

    if (userId) {
      dispatch(getLogbooks({ userId, orgId }));
    }

    return function cleanup() {
      dispatch(resetLogbooks());
      dispatch(resetApprenticeships());
    };
  }, []);

  // useEffect(() => {
  //   if (apprenticeship?.id) {
  //     setTimeout(() => {
  //       velaInstructions?.current?.classList?.add('animateIn');
  //       setTimeout(() => {
  //         getStartedButton?.current?.classList?.add('animateIn');
  //       }, 2500);
  //     }, 500);
  //   }
  // }, [apprenticeship?.id]);

  if (!currentUser.id || !organization.id) {
    return (
      <Loading
        className='position-relative'
        style={{ background: '#121212' }}
      />
    );
  }

  if (!canAccessLogbook(role)) {
    return <Redirect to={SLASH} />;
  }

  if (!apprenticeshipIds || (Array.isArray(apprenticeshipIds) && apprenticeshipIds.length === 0)) {
    toast.info("You're not enrolled in any Training Programs.");
    return <Redirect to={SLASH} />;
  }

  if (!apprenticeships.fetched && !apprenticeships.list) {
    return (
      <Loading
        className='position-relative'
        style={{ background: '#121212' }}
        text="Loading Training Programs..."
      />
    );
  }

  if (apprenticeships.fetched && !apprenticeships.list) {
    const message = `I received an error that I'm not enrolled in a Training Program.

(apprenticeshipIds: ${apprenticeshipIds.join(', ')})
(href: ${window.location.href})`;

    dispatch(openReportIssueModal({
      fromName: currentUser?.name,
      replyTo: currentUser?.email,
      orgName,
      message
    }));

    return (
      <div className='text-white text-center my-4'>
        <p>
          No Training Programs.
        </p>
      </div>
    );
  }

  if (!logbooks.fetched && !logbooks.list) {
    return (
      <Loading
        className='position-relative'
        style={{ background: '#121212' }}
        text="Loading logbooks..."
      />
    );
  }

  if (
    Array.isArray(serializedLogbooksList)
    && serializedLogbooksList.length > 0
    && Array.isArray(apprenticeships.list)
    && apprenticeships.list.length > 0
  ) {
    return (
      <div>
        <div
          className='BoxText my-3'
        >
          Logbooks
        </div>

        {serializedLogbooksList.map((item) => {
          return (
            <ProgramContainer
              className='mb-3'
              key={item?.id}
              apprenticeshipId={item?.apprenticeshipId}
              apprenticeshipTitle={item?.apprenticeshipTitle}
              logbookId={item?.id}
            >
              <div>
                <Link
                  className='btn btn-sm btn-outline-white'
                  to={`${RECORDS}?logbookId=${item?.id}`}
                >
                  <span className='d-flex align-items-center'>
                    <FaListAlt />
                    <span className='ml-1'>
                      Records
                    </span>
                  </span>
                </Link>
                <Link
                  className='btn btn-sm btn-primary ml-2'
                  to={`${LOGBOOK}?logbookId=${item?.id}&postNewRecord=true`}
                >
                  <span className='d-flex align-items-center'>
                    <FaPlus />
                    <span className='d-none d-sm-flex ml-1'>
                      New
                    </span>
                    <span className='ml-1'>
                      Record
                    </span>
                  </span>
                </Link>
              </div>
            </ProgramContainer>
          );
        })}

        {availableApprenticeshipsList.length > 0 && (
          <LogbookForm
            availableApprenticeshipsList={availableApprenticeshipsList}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <div
        className='Box mt-5 mb-3 px-2 rounded shadow'
        style={{ minHeight: 'auto' }}
      >
        <div className='BoxSubText'>
          Hi {currentUser?.firstName}!
        </div>
        <div
          className='BoxText'
          style={{ minHeight: 'auto' }}
        >
          I'm VELA. I simplify task tracking.
        </div>
      </div>

      {availableApprenticeshipsList.length > 0 && (
        <>
          <div
            className='Box p-2 rounded shadow mb-0'
            style={{ minHeight: 'auto' }}
          >
            <div
              className='BoxSubText'
              style={{ color: '#e2e2e2' }}
            >
              Get started by creating your first logbook
            </div>
          </div>

          <LogbookForm
            availableApprenticeshipsList={availableApprenticeshipsList}
          />
        </>
      )}
    </div>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(Logbooks);
