import {
  SET_LOGBOOK, RESET_LOGBOOK
} from '../../actions/types';
import logbookState from '../initialState/logbookState';

const initialState = {
  ...logbookState,
  fetched: false
};

export default function logbook(state = initialState, action) {
  switch (action.type) {
    case SET_LOGBOOK:
      return {
        ...state,
        ...action.logbook,
        fetched: true
      };
    case RESET_LOGBOOK:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
