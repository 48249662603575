import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import { sendEmail } from '../../../services/users';
import { resetCurrentModal } from '../../../actions/Modals';
import { notifyTurbineSupport } from '../../../services/organizations';
import { slackReportIssue } from '../../../services/slack';
import Modal from '../../Modal';
import './style.css';

const SendEmail = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgName = pathOr(null, ['name'], organization);
  // Modal Data
  const modalData = pathOr({}, ['data'], currentModal);
  const modalTitle = pathOr('Send Email', ['modalTitle'], modalData);
  const disableSubject = pathOr(false, ['disableSubject'], modalData);
  const hideSubject = pathOr(false, ['hideSubject'], modalData);
  const email = pathOr('', ['email'], modalData);
  const name = pathOr('', ['name'], modalData);
  const notifyTurbineEmail = pathOr(false, ['notifyTurbineEmail'], modalData);
  const notifyTurbineSlack = pathOr(false, ['notifyTurbineSlack'], modalData);
  const replyTo = pathOr('', ['replyTo'], modalData);
  const fromName = pathOr(`${currentUser?.userName}`, ['fromName'], modalData);
  // Local State
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState(currentModal?.data?.subject || '');
  const [message, setMessage] = useState(currentModal?.data?.message || '');

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleMsgSentSuccessfully = (toastText) => {
    toast.success(toastText);
    handleClose();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      to: email,
      toName: name,
      fromName,
      replyTo,
      subject,
      message,
      orgName
    };

    setLoading(true);

    if (notifyTurbineEmail) {
      notifyTurbineSupport({
        ...data
      }).then(() => {
        if (notifyTurbineSlack) {
          slackReportIssue({
            message,
            fromName,
            fromEmail: replyTo,
            orgName
          }).then(() => {
            handleMsgSentSuccessfully('Issue reported!');
          });
        } else {
          handleMsgSentSuccessfully('Support request sent!');
        }
      });
    } else {
      sendEmail({
        ...data
      }).then(() => {
        handleMsgSentSuccessfully('Message sent!');
      });
    }
  };

  if (currentModal && !currentModal.visible) return null;

  return (
    <Modal
      cssClassName={`turbine-modal--${currentModal?.key}`}
      visible={currentModal.visible}
      close={handleClose}
      pos="middle"
    >
      <div className="card">
        <form
          id="send-email"
          name="send-email"
          onSubmit={onSubmit}
        >
          <div className="card-header">
            <h1 className="h5 m-0">
              {modalTitle}
            </h1>
          </div>
          <div className="card-body">
            {/* <div className="mb-2">
              <b>To:</b>
              <span
                data-demo="disabled"
                className="ml-2 badge-pill badge-light border"
              >
                {name} {email && (<span>({email})</span>)}
              </span>
            </div> */}

            <div
              className="mb-2"
              style={{ display: hideSubject ? 'none' : 'block' }}
            >
              <input
                id="send-email-subject"
                className="form-control"
                name="subject"
                placeholder="subject"
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setSubject(value);
                }}
                defaultValue={subject}
                disabled={disableSubject}
                type="text"
                required
                autoFocus={!disableSubject}
              />
            </div>

            <div>
              <p
                className="text-muted"
                data-demo="disabled"
              >
                Hi {name},
              </p>
              <textarea
                id="send-email-message"
                className="form-control mb-2"
                name="message"
                style={{ minHeight: '150px' }}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setMessage(value);
                }}
                value={message}
                autoFocus={disableSubject || hideSubject}
                required
              />
              <p className="text-muted">
                Best,
                <br />
                {fromName}
              </p>
            </div>
          </div>
          <div className="card-footer">
            <button
              className="btn btn-primary btm-sm"
              type="submit"
              disabled={loading}
            >
              <i className="fas fa-paper-plane" /> {loading ? 'Sending...' : 'Send'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SendEmail;
