import React from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentModal } from '../../../../../actions/Modals';
import ResourceIcon from './ResourceIcon';
import { setSideSheet } from '../../../../../actions/SideSheets';
import { MODAL_KEY_RESOURCE_PREVIEW } from '../../../../../constants/modals';
import { SHEET_KEY_MATERIAL } from '../../../../../constants/sideSheets';
import { getMaterial, resetMaterial } from '../../../../../actions/Materials';

const NameCell = ({ value: name, row, resourcePreview }) => {
  const dispatch = useDispatch();
  // Resource
  const id = row?.original?.id || '';
  const type = row?.original?.type || '';
  const url = row?.original?.url || '';
  const isEmbedded = row?.original?.isEmbedded || null;

  const onClickPreview = () => {
    dispatch(setCurrentModal({
      key: MODAL_KEY_RESOURCE_PREVIEW,
      data: {
        resource: row?.original,
        modalTitle: name,
        iframeSrc: url
      }
    }));
  };

  const onClickMaterialPreview = () => {
    dispatch(getMaterial(id));
    dispatch(setSideSheet({
      key: SHEET_KEY_MATERIAL,
      className: 'MaterialSideSheet',
      callbackPrimaryAction: () => {
        dispatch(resetMaterial());
        //
      }
    }));
  };

  if (type === 'link') {
    return (
      <div className='d-flex align-items-center'>
        <ResourceIcon
          type={type}
          url={url}
          name={name}
          isEmbedded={isEmbedded}
        />
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-link ml-2 text-left"
          title="View in new tab"
          aria-label="View in new tab"
          data-cy="CdnFile-Name"
          data-filename={name}
          data-id={id}
        >
          {name}
        </a>
      </div>
    );
  }

  if (type === 'material') {
    return (
      <div className='d-flex align-items-center'>
        <ResourceIcon
          type={type}
          url={url}
          name={name}
          isEmbedded={isEmbedded}
        />
        {resourcePreview ? (
          <button
            type="button"
            onClick={onClickMaterialPreview}
            className="btn-link ml-2 text-left"
            title="Preview"
            aria-label="Preview"
            data-cy="CdnFile-Name"
            data-filename={name}
            data-id={id}
          >
            {name}
          </button>
        ) : (
          <span
            className=" ml-2 text-left"
          >
            {name}
          </span>
        )}
      </div>
    );
  }

  return (
    <div className='d-flex align-items-center'>
      <ResourceIcon
        type={type}
        url={url}
        name={name}
        isEmbedded={isEmbedded}
      />

      {resourcePreview ? (
        <button
          type="button"
          onClick={onClickPreview}
          className="btn-link ml-2 text-left"
          title="Preview"
          aria-label="Preview"
          data-cy="CdnFile-Name"
          data-filename={name}
          data-id={id}
        >
          {name}
        </button>
      ) : (
        <span
          className=" ml-2 text-left"
        >
          {name}
        </span>
      )}
    </div>
  );
};

export default NameCell;
