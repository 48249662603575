// import { createSpeechlySpeechRecognition } from '@speechly/speech-recognition-polyfill';
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// import {
//   useSpeechContext
// } from '@speechly/react-client';
// import {
//   PushToTalkButton,
//   IntroPopup
// } from '@speechly/react-ui';
// import { createCompletion } from '../../services/openApi';
// import { createLogbook, updateLogbook, classifyInput } from '../../services/logbooks';
// import TalkButtonLoader from './TalkButtonLoader';
// import LoadingWave from './LoadingWave';

import React, {
  useCallback,
  useEffect, useMemo, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { FaListAlt, FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useFlags, useFlagsmith } from 'flagsmith/react';
import {
  FLAG_LOGBOOKS_VELA_READONLY,
  NOTICE_LOGBOOKS_READONLY_TITLE,
  NOTICE_MAINTENANCE_MODE_BODY
} from '../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../actions/FeatureFlags';
import { withAuthorization } from '../../components/Session';
import {
  LOGBOOK, LOGBOOKS, RECORDS, SLASH
} from '../../constants/routes';
import { canAccessLogbook } from '../../services/currentUser';
import { errorLogbookNotFound, getLogbook, resetLogbook } from '../../actions/Logbooks';
import { getGreeting } from '../../utils/date';
import { getApprenticeship, resetApprenticeship } from '../../actions/Apprenticeships';
import { goToElem } from '../../services/manageContent';
import { getLogbookRecords, resetLogbookRecords } from '../../actions/LogbookRecords';
import Loading from '../../components/Loading';
import RecordForm from '../../components/RecordForm';
import RecordContainer from './RecordContainer';
import ProgramContainer from '../../components/Lists/ProgramsList/ProgramContainer';
import LogbookNotFound from '../../components/LogbookNotFound';
import LogbookProgress from '../../components/LogbookProgress';
import './style.css';
import LogbookRecords from '../../components/LogbookRecords';

const Logbook = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const flags = useFlags([FLAG_LOGBOOKS_VELA_READONLY]);
  const { getFlags } = useFlagsmith();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbook = useSelector((state) => state.logbook);
  const logbookRecords = useSelector((state) => state.logbookRecords);
  // Params
  const searchParams = new URLSearchParams(window.location.search);
  const logbookId = searchParams.get('logbookId');
  const postNewRecord = searchParams.get('postNewRecord');
  // Current User
  const userId = currentUser?.id || '';
  const role = currentUser?.role || [];
  const apprenticeshipIds = currentUser?.apprenticeshipIds || [];
  const firstName = currentUser?.firstName || null;
  // Organization
  const orgId = organization?.id || '';

  // Apprenticeship
  // const classifications = apprenticeship?.classifications || {};
  // const competencies = apprenticeship?.competencies || {};
  // Local State
  // const [selectedCompetencies, setSelectedCompetencies] = useState([]);
  // const [taskDateReadable, setTaskDateReadable] = useState('TASK');
  // const [taskHoursReadable, setTaskHoursReadable] = useState(0);
  // const [showLoadingWave, setShowLoadingWave] = useState(false);
  // Local State
  // const {
  //   segment,
  //   tentativeTranscript
  // } = useSpeechContext();
  // const [hideTalkButton, setHideTalkButton] = useState(true);
  // const [tentativeTranscriptLocal, setTentativeTranscriptLocal] = useState('');

  const [showRecordForm, setShowRecordForm] = useState(false);

  const welcomeMessage = useRef(null);
  const greeting = useMemo(() => {
    return getGreeting();
  }, []);

  const recordFormCallback = () => {
    goToElem('TopNav');

    if (postNewRecord) {
      history.replace(`${LOGBOOK}?logbookId=${logbookId}`);
    }

    setShowRecordForm(false);
  };

  const handleShowCreateRecordForm = useCallback(() => {
    getFlags();

    if (flags?.logbooks_vela_readonly?.enabled && flags?.logbooks_vela_readonly?.value !== undefined) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_LOGBOOKS_READONLY_TITLE,
        modalBody: NOTICE_MAINTENANCE_MODE_BODY
        // preventClose: true
      }));
      setShowRecordForm(false);
      if (postNewRecord) {
        history.replace(`${LOGBOOK}?logbookId=${logbookId}`);
      }
      return;
    }

    if (postNewRecord) {
      setShowRecordForm(true);
    } else {
      history.push(`${LOGBOOK}?logbookId=${logbookId}&postNewRecord=true`);
    }

    setTimeout(() => {
      goToElem('RecordForm');
    }, 50);
  }, [postNewRecord]);

  useEffect(() => {
    dispatch(resetLogbookRecords());

    dispatch(getLogbook({
      id: logbookId,
      orgId,
      logbookHours: true
    }))
      .then((response) => {
        if (response?.orgId !== currentUser?.orgId) {
          toast.error('Logbook Organization Access Denied ', { autoClose: false });
          history.replace(SLASH);
          return;
        }

        if (response?.userId !== currentUser?.id) {
          toast.error('Logbook User Access Denied', { autoClose: false });
          history.replace(SLASH);
          return;
        }

        dispatch(getLogbookRecords({ logbookId, limit: 1 }));

        if (response?.apprenticeshipId) {
          dispatch(getApprenticeship(response?.apprenticeshipId));
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(errorLogbookNotFound({
          logbookId,
          orgId: organization?.id,
          orgName: organization?.name,
          userId,
          userName: currentUser.name,
          userEmail: currentUser?.email,
          userLocationId: currentUser?.locationId
        }));
      });

    return function cleanup() {
      dispatch(resetApprenticeship());
      dispatch(resetLogbook());
    };
  }, []);

  useEffect(() => {
    if (postNewRecord === 'true') {
      handleShowCreateRecordForm();
    } else {
      setShowRecordForm(false);
    }
  }, [postNewRecord]);

  if (!currentUser.id || !organization.id) {
    return (
      <Loading
        className="position-relative"
        style={{ background: '#121212' }}
      />
    );
  }

  if (!apprenticeshipIds || (Array.isArray(apprenticeshipIds) && apprenticeshipIds.length === 0)) {
    return <Redirect to={SLASH} />;
  }

  if (!canAccessLogbook(role)) {
    toast.error('You don\'t have access to Logbooks.');

    return <Redirect to={SLASH} />;
  }

  if (!logbookId) {
    toast.error('Missing Logbook ID');
    return <Redirect to={LOGBOOKS} />;
  }

  if (logbookId && !logbook?.id && !logbook.fetched) {
    return (
      <Loading
        className="position-relative"
        style={{ background: '#121212' }}
      />
    );
  }

  if (logbookId && !logbook?.id && logbook.fetched) {
    return (
      <LogbookNotFound />
    );
  }

  // if (!browserSupportsSpeechRecognition) {
  //   return <span>Browser doesn't support speech recognition.</span>;
  // }

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-sm-9 col-lg-6">

        <div className="welcomeMessage animateIn" ref={welcomeMessage}>
          <div
            className="Box my-3 px-5 py-3 rounded shadow flex-column d-flex justify-content-center"
            style={{
              border: '1px solid #1d1d1d'
            }}
          >
            <div className='BoxSubText'>
              {greeting}, {firstName}
            </div>
            <div className='BoxText'>
              What <span style={{ color: '#bb86fc' }}>tasks</span> did you work
              on today?
            </div>
          </div>
        </div>

        {showRecordForm && (
          <RecordForm
            onSuccessCallback={recordFormCallback}
            onCancelCallback={recordFormCallback}
            onErrorCallback={recordFormCallback}
          />
        )}

        {logbook?.id && !showRecordForm && (
          <RecordContainer
            className='mb-3'
            style={{
              minHeight: 'auto',
              background: 'none',
              border: '1px #1d1d1d solid'
            }}
          >
            <button
              type="button"
              className='btn btn-md btn-primary'
              onClick={handleShowCreateRecordForm}
            >
              <span className='d-flex align-items-center'>
                <FaPlus size={15} />
                <span className='ml-2'>
                  New Record
                </span>
              </span>
            </button>
          </RecordContainer>
        )}

        <div
          className={`my-3 ${showRecordForm && 'filter-blur-5'}`}
          style={{ minHeight: '100px' }}
        >
          {logbook?.id && apprenticeship?.id && (
            <ProgramContainer
              className="animateIn"
              apprenticeshipId={apprenticeship?.id}
              apprenticeshipTitle={apprenticeship?.title}
              logbookId={logbook?.id}
            >
              <div className='w-100'>
                <LogbookProgress
                  className='w-100'
                  records={logbookRecords.list}
                  ojtHours={apprenticeship?.ojtHours}
                />

                <LogbookRecords
                  data={logbookRecords.list}
                  className="w-100 mt-4"
                />

                {Array.isArray(logbookRecords?.list) && logbookRecords.list.length !== 0 && (
                  <RecordContainer
                    className={`my-3 ${showRecordForm && 'filter-blur-5'}`}
                    style={{
                      minHeight: 'auto',
                      // background: 'none',
                      border: '1px #1d1d1d solid'
                    }}
                  >
                    <Link
                      className='btn btn-md btn-link text-white'
                      disabled={showRecordForm}
                      to={`${RECORDS}?logbookId=${logbook?.id}`}
                    >
                      <span className='d-flex align-items-center'>
                        <FaListAlt />
                        <span className='ml-2'>
                          View all records ({logbookRecords?.pagination?.total})
                        </span>
                      </span>
                    </Link>
                  </RecordContainer>
                )}
              </div>
            </ProgramContainer>
          )}
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(Logbook);

// useEffect(() => {
//   if (apprenticeship?.id) {
//     setTimeout(() => {
//       welcomeMessage?.current?.classList?.add('animateIn');
//       setHideTalkButton(false);
//     }, 500);
//   }
// }, [apprenticeship?.id]);

// useEffect(() => {
//   setTentativeTranscriptLocal(tentativeTranscript);
//   setSelectedCompetencies([]);
//   setTaskDateReadable('TASK');
//   setTaskHoursReadable(0);
//   if (segment?.isFinal && tentativeTranscriptLocal?.text !== undefined) {
//     setHideTalkButton(true);
//     setTentativeTranscriptLocal(undefined);
//     showClassifyingTaskAnimation();
//     createTimeLog(segment);
//   }
// }, [segment]);

// const showClassifyingTaskAnimation = () => {
//   setTimeout(() => {
//     setShowLoadingWave(true);
//     welcomeMessage?.current?.classList?.replace('animateIn', 'animateOut');
//   }, 100);
// };

// const createRecordTrackingEvent = (eventName, { logbookId, firstRecord }) => {
//   heapAnalytics.track(eventName, {
//     type: 'voice',
//     firstRecord,
//     ...(logbookId ? { logbookId } : {}),
//     orgId,
//     apprenticeshipId,
//     userId,
//     employerId: userLocation?.id
//   });
// };

// const somethingWentWrong = (error) => {
//   console.error(error);
//   toast.error('Something went wrong, refresh and try again', {
//     autoClose: false
//   });
//   setHideTalkButton(false);
//   setShowLoadingWave(false);
//   history.replace(PROGRAMS);
// };

// const createTimeLog = async (segmentData) => {
//   const transcriptSegment = segmentData.words.map((w) => w.value).join(' ');

//   const stopChar = '\n';
//   let prompt = 'Choose zero or a maximum of three Tags that are the most relevant to the following text:';
//   prompt += stopChar; // 2
//   // Aggregated Apprenticeship Classifications
//   prompt += Object.values(classifications).join(', '); // 'Sub-Assembly Repair, Tool Maintenance, Electrical Supply Installation, Electrical Component Repair, Electrical Troubleshooting, Business Management, Human Resources, Workshop safety, Manufacturing Planning';
//   prompt += stopChar; // 2
//   // Human input
//   prompt += transcriptSegment; // 'I learned how to repair the CNC machine and how to protect myself while working in the shop';
//   prompt += stopChar; // 2
//   prompt += 'Tags:';
//   prompt += stopChar;

//   const response = await createCompletion({
//     prompt
//   });

//   let input;

//   if (response?.data && typeof response?.data === 'string') {
//     input = response?.data;
//   }

//   // If input is undefined, the NLP was unable to classify the text spoken given the provided values
//   const {
//     // classificationIds,
//     competencyIds
//   } = classifyInput({
//     competencies,
//     classifications,
//     input
//   });

//   const selectedCompetencyIds = [...competencyIds];

//   // Save selected competencies
//   if (selectedCompetencyIds?.length > 0) {
//     // Given: there are more than four competencyIds selected by VELA
//     if (selectedCompetencyIds.length >= 4) {
//       // Then: only save the first three
//       selectedCompetencyIds.splice(3, selectedCompetencyIds.length - 1);
//     }

//     // Then: show user which competencies were identified/saved
//     setSelectedCompetencies(
//       selectedCompetencyIds.map((id) => competencies[id])
//     );
//   }

//   const createdAt = dateTimestamp();
//   let taskDate = createdAt;
//   let hours = 0;

//   // Review Entities
//   if (
//     segmentData.entities
//     && Array.isArray(segmentData.entities)
//     && segmentData.entities.length !== 0
//   ) {
//     segmentData.entities.forEach((entity) => {
//       // DATE (TASK DATE)
//       if (entity?.type === 'task_date') {
//         const dateStringTime = taskDate.split('T');
//         const dateString = `${entity?.value}T${dateStringTime[1]}`;

//         taskDate = dateTimestamp(dateString);
//       }
//       // HOURS (TIME AS SPOKEN)
//       if (entity?.type === 'time_as_spoken') {
//         // Convert to hours
//         hours = entity?.value / 60;
//         setTaskHoursReadable(formatHours(hours));
//       }
//     });
//   }

//   // TODO handle try again UI when VELA didn't pickup any hours
//   // if (hours === 0) {
//   //   // Handle try again
//   // }

//   setTaskDateReadable(mdySimple(taskDate));

//   const newRecord = {
//     id: uuid(),
//     createdAt,
//     date: taskDate,
//     competencyIds: selectedCompetencyIds,
//     hours,
//     original: {
//       transcript: transcriptSegment
//     }
//   };

//   const dataToSave = {
//     orgId,
//     apprenticeshipId,
//     userId,
//     userName,
//     employerId: userLocation?.id,
//     records: [newRecord]
//   };

//   if (logbook?.id) {
//     // Update logbook records
//     updateLogbook(
//       {
//         records: [
//           newRecord,
//           ...(logbook?.records?.length ? logbook.records : [])
//         ]
//       },
//       logbook?.id
//     )
//       .then(() => {
//         createRecordTrackingEvent('Record - Create', {
//           firstRecord: false,
//           logbookId: logbook?.id
//         });
//         dispatch(getLogbook({ id: logbook?.id })).then(() => {
//           toast.success('Record saved to Logbook!');
//           setHideTalkButton(false);
//           setShowLoadingWave(false);
//         });
//       })
//       .catch((error) => {
//         createRecordTrackingEvent('Error - Record - Create', {
//           firstRecord: false,
//           logbookId: logbook?.id,
//           error: 'Problem updating Logbook'
//         });
//         somethingWentWrong(error);
//       });
//   } else {
//     // Create logbook
//     toast.info('Processing...');

//     createLogbook(dataToSave)
//       .then((docId) => {
//         if (docId) {
//           createRecordTrackingEvent('Create - Record', {
//             firstRecord: true,
//             logbookId: docId
//           });
//           dispatch(getLogbook({ id: docId })).then(() => {
//             toast.success('Record saved to Logbook!');
//             setHideTalkButton(false);
//             setShowLoadingWave(false);
//           });
//         } else {
//           createRecordTrackingEvent('Error - Record - Create', {
//             firstRecord: true,
//             error: 'No Logbook ID returned'
//           });
//           somethingWentWrong('No Logbook ID returned');
//         }
//       })
//       .catch((error) => {
//         createRecordTrackingEvent('Error - Record - Create', {
//           firstRecord: true,
//           error: 'Problem creating Logbook'
//         });
//         somethingWentWrong(error);
//       });
//   }
// };

// const apprentageMockSegment = {
//   words: [
//     { value: 'worked' },
//     { value: 'on' },
//     { value: 'a' },
//     { value: 'new' },
//     { value: 'lathe' },
//     { value: 'machine' },
//     { value: 'for' },
//     { value: 'about' },
//     { value: 'three' },
//     { value: 'hours' },
//     { value: 'today' }
//   ]
// };

// {{!hideTalkButton && (
//   <PushToTalkButton
//     powerOn="auto"
//     placement="bottom"
//     hide={hideTalkButton}
//   />
// )}

// <TalkButtonLoader hide={!hideTalkButton} />

// <button
//  type="button"
//  className="KeyboardInputButton btn btn-outline-primary"
//  onClick={() => {
//    dispatch(
//      setCurrentModal({
//        key: 'manageRecord'
//      })
//    );
//  }}
//  data-tip="<b>PRESS TO TYPE</b>"
// >
//  <i className="fa fa-keyboard-o" aria-hidden="true" />
// </button>
// <ReactTooltip className="speechly-tooltip" html place="top" />

// {<IntroPopup />}
// {<span slot="priming-body">You will be able to book faster with voice.</span>}

// {<div className="status">
// {stateToString(clientState)}
// </div>}

// {segment && (
//   <div className="ColoredBox rounded shadow position-relative p-3 mt-3">
//       <div className="d-flex align-items-center justify-content-between mb-3">
//         <div className="BoxSubText">{taskDateReadable}</div>

//         <TimeBadge duration={taskHoursReadable} />
//       </div>
//       <div className="BoxText text-center">
//         {segment.words.map((w) => w.value).join(' ')}
//       </div>
//       {showLoadingWave && <LoadingWave type="contained" />}
//     </div>
//   )}

//   {segment && selectedCompetencies && selectedCompetencies.length !== 0 && (
//   <div className="mt-3">
//     <div className="BoxText mb-3" style={{ fontSize: '1rem' }}>
//       {`Record categorized in ${selectedCompetencies.length === 1 ? 'this competency' : 'these competencies'}`}
//     </div>

//     {selectedCompetencies.map(({ description }, i) => (
//       <MatchedCompetency key={`selected-comp-${i}`}>
//         {description}
//         <CompetencyCheckMark>
//           <i className={ICON_CIRCLE_CHECK} />
//         </CompetencyCheckMark>
//       </MatchedCompetency>
//     ))}
//   </div>
// )}

// {apprentageMockSegment?.words?.length !== 0 && (
//   <div className='ColoredBox p-5 rounded shadow'>
//     <div className="BoxSubText">
//       {mdySimple(new Date())}
//     </div>
//     <div className="BoxText">
//       {apprentageMockSegment.words.map((w) => w.value).join(" ")}
//     </div>
//   </div>
// )}

// <button
//   onClick={() => {
//     classifyInput({
//       competencies,
//       classifications
//     });
//   }}
//   className="btn btn-lg btn-primary"
// >
//   Test
// </button>

// return (
//   <div>
//     <div
//       className='Box mt-5 mb-3 px-2 rounded shadow'
//       style={{ minHeight: 'auto' }}
//     >
//       <div className="BoxSubText">
//         Hi {currentUser?.firstName}!
//       </div>
//       <div
//         className="BoxText"
//         style={{ minHeight: 'auto' }}
//       >
//         I'm VELA. I simplify task tracking.
//       </div>
//     </div>

//     <div
//       className='velaInstructions'
//       ref={velaInstructions}
//     >
//       <div
//         className='Box p-2 rounded shadow mb-0'
//         style={{ minHeight: 'auto' }}
//       >
//         <div className="BoxSubText" style={{ color: '#e2e2e2' }}>
//           I understand natural language, like...
//         </div>
//       </div>

//       <div
//         className='ColoredBox px-3 py-4 rounded shadow my-2'
//         style={{ minHeight: 'auto' }}
//       >
//         <div
//           className='BoxText d-flex align-items-center text-center'
//           style={{ minHeight: 'auto' }}
//         >
//           <div className='w-100'>
//             <span className="TextYellow">Today</span> I worked on the <span className="TextPurple">CNC machine</span> for <span className="TextBlue">three hours</span>.
//           </div>
//         </div>
//       </div>

//       <div
//         className='Box p-2 rounded shadow mb-0'
//         style={{ minHeight: 'auto' }}
//       >
//         <div className="BoxSubText">
//           <span className="TextYellow">Date</span> = {mdySimple(new Date())}
//         </div>
//         <div className="BoxSubText">
//           <span className="TextPurple">Action</span> = Task or Machine you worked
//         </div>
//         <div className="BoxSubText">
//           <span className="TextBlue">Time</span> = 3 Hours
//         </div>
//       </div>
//     </div>

//     <div
//       ref={getStartedButton}
//       className='mt-4 d-flex justify-content-center text-center velaInstructions'
//     >
//       <button
//         className='btn btn-md btn-white font-weight-bold'
//         onClick={() => {
//           history.push(`${LOGBOOK}?apprenticeshipId=${apprenticeshipId}`);
//         }}
//         type="button"
//       >
//         Get Started <i className='fas fa-arrow-right ml-2' />
//       </button>
//     </div>
//   </div>
// );
