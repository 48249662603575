import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import {
  client, clientManagement, CSPACE, flattenItems
} from '../services/contentful';
import { getLocations } from './Locations';
import {
  SET_ORG,
  SET_SYNDICATED_COURSE_ORGS,
  RESET_SYNDICATED_COURSE_ORGS,
  RESET_ORG,
  SET_ORG_SKILLS,
  RESET_ORG_SKILLS
} from './types';
import { fetchOrg } from '../services/organizations';

export const getOrg = ({ orgId, include = 3 }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!orgId) {
        const orgFailedError = 'An id is required to get an Organization';

        toast.error(orgFailedError);
        reject(new Error(orgFailedError));
      }

      // SET LOCATIONS by ORG ID
      dispatch(getLocations({ orgId })).then(() => {
        // FETCH ORG
        fetchOrg({ orgId, include }).then((organization) => {
          const userGroupIds = pathOr(null, ['userGroupIds'], organization);
          const courseGroupIds = pathOr(null, ['courseGroupIds'], organization);
          const resourceGroupIds = pathOr(null, ['resourceGroupIds'], organization);
          const orgSkills = pathOr(null, ['orgSkills', 'list'], organization);
          const cdnFiles = pathOr(null, ['integration', 'cdnFiles'], organization);
          const data = { organization };

          if (userGroupIds) data.userGroupIds = userGroupIds; // TODO refactor & remove from ORG state?
          if (courseGroupIds) data.courseGroupIds = courseGroupIds; // TODO refactor & remove from ORG state?
          if (resourceGroupIds) data.resourceGroupIds = resourceGroupIds; // TODO refactor & remove from ORG state?
          if (cdnFiles) data.cdnFiles = cdnFiles;
          if (orgSkills) data.orgSkills = orgSkills;

          dispatch({ type: SET_ORG, ...data });
          resolve(data);
        }).catch((error) => {
          toast.error(error.message);
          reject(error);
        });
      }).catch((error) => {
        toast.error(error.message);
        reject(error);
      });
    });
  };
};

export const getSyndicatedCourseOrgs = ({
  orgId,
  orgIds,
  include,
  select = ['fields.name']
}) => {
  // Prevent requesting the current user's Org if the current
  // Org's Id is present in the Course's orgIds.
  const sanitizedOrgIds = orgIds.filter((o) => o !== orgId);

  const config = {
    content_type: 'organization',
    include,
    'sys.id[in]': orgIds.join(','),
    select: select.join(',')
  };

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!sanitizedOrgIds.length) {
        dispatch({
          type: SET_SYNDICATED_COURSE_ORGS,
          syndicatedCourseOrgs: null
        });

        resolve(null);

        return null;
      }

      client.getEntries(config).then(({ items }) => {
        const syndicatedCourseOrgs = items.length ? flattenItems(items) : null;

        dispatch({
          type: SET_SYNDICATED_COURSE_ORGS,
          syndicatedCourseOrgs
        });

        resolve(syndicatedCourseOrgs);
      }).catch((error) => {
        console.error(error.message);
        reject(error);
      });
    });
  };
};

export const saveOrgSkill = ({ orgSkill }) => {
  return (dispatch, getState) => {
    const { organization } = getState();
    const { id: orgId } = organization;

    return new Promise((resolve, reject) => {
      clientManagement.getSpace(CSPACE)
        .then((space) => space.getEnvironment('master'))
        .then((environment) => environment.getEntry(orgId))
        .then((entry) => {
          if (orgSkill !== undefined) {
            if (entry.fields.orgSkills === undefined) {
              entry.fields.orgSkills = { 'en-US': {} };
            }

            if (entry.fields.orgSkills['en-US'].list === undefined) {
              entry.fields.orgSkills['en-US'].list = {};
            }

            entry.fields.orgSkills['en-US'].list = {
              ...entry.fields.orgSkills['en-US'].list,
              ...orgSkill
            };
          }

          return entry.update();
        })
        .then((entry) => entry.publish())
        .then((entry) => {
          setTimeout(() => {
            let orgSkills;

            if (entry.fields.orgSkills && entry.fields.orgSkills['en-US'] && entry.fields.orgSkills['en-US'].list) {
              orgSkills = entry.fields.orgSkills['en-US'].list;
            }

            dispatch({
              type: SET_ORG_SKILLS,
              orgSkills
            });

            resolve(entry);
          }, 250);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const resetOrg = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ORG });
  };
};

export const resetSyndicatedCourseOrgs = () => {
  return (dispatch) => {
    dispatch({ type: RESET_SYNDICATED_COURSE_ORGS });
  };
};

export const resetOrgSkills = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ORG_SKILLS });
  };
};
