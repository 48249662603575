function formatUser(data, entry) {
  const {
    membership,
    role,
    groupIds, // String[]
    classId,
    classIds, // String[]
    courseIds, // (NEW) String[] || null
    completedClassId, // (NEW) String[] || null
    completedClassIdsToRemove, // (NEW) String[] || null
    completedTopicId, // (NEW) String[] || null
    completedTopicIdsToRemove, // (NEW) String[] || null
    cohortId,
    cohortIds, // (NEW) String[] || null
    eUnitIds, // String[]
    extRegClassId,
    apprenticeshipId,
    apprenticeshipIds, // String[]
    userTagIds, // (NEW) String[]
    supervisorIds, // (NEW) String[]
    uniqueCertificateId, // (NEW)
    uniqueCertificateData, // (NEW) Object
    ...remainingData
  } = data;

  const dataToSave = {
    ...remainingData
  };

  if (membership !== undefined) {
    dataToSave.membership = [membership];
  }

  if (role !== undefined) {
    dataToSave.role = [role];
  }

  // User Group IDs
  if (groupIds !== undefined) {
    dataToSave.groupIds = Array.isArray(entry?.groupIds) ? entry?.groupIds : [];

    // De-dupe
    if (Array.isArray(groupIds)) {
      groupIds.forEach((id) => {
        if (!dataToSave.groupIds.includes(id)) {
          dataToSave.groupIds.push(id);
        }
      });
    }

    // Null
    if (groupIds === null) {
      dataToSave.groupIds = groupIds;
    }
  }

  // User Tags
  if (userTagIds !== undefined) {
    dataToSave.userTagIds = Array.isArray(entry?.userTagIds) ? entry?.userTagIds : [];

    // De-dupe
    if (Array.isArray(userTagIds)) {
      userTagIds.forEach((id) => {
        if (!dataToSave.userTagIds.includes(id)) {
          dataToSave.userTagIds.push(id);
        }
      });
    }

    // Null
    if (userTagIds === null) {
      dataToSave.userTagIds = userTagIds;
    }
  }

  // Supervisors
  if (supervisorIds !== undefined) {
    dataToSave.supervisorIds = Array.isArray(entry?.supervisorIds) ? entry?.supervisorIds : [];

    // De-dupe
    if (Array.isArray(supervisorIds)) {
      supervisorIds.forEach((id) => {
        if (!dataToSave.supervisorIds.includes(id)) {
          dataToSave.supervisorIds.push(id);
        }
      });
    }

    // Null
    if (supervisorIds === null) {
      dataToSave.supervisorIds = supervisorIds;
    }
  }

  // Course Enrollment
  if (classId !== undefined || classIds !== undefined) {
    dataToSave.courseIds = Array.isArray(entry?.courseIds) ? entry?.courseIds : [];

    // De-dupe
    if (classId) {
      if (!dataToSave.courseIds.includes(classId)) {
        dataToSave.courseIds.push(classId);
      }
    }

    // De-dupe
    if (Array.isArray(classIds)) {
      classIds.forEach((id) => {
        if (!dataToSave.courseIds.includes(id)) {
          dataToSave.courseIds.push(id);
        }
      });
    }
  }

  // Null
  if (courseIds !== undefined) {
    dataToSave.courseIds = courseIds;
  }

  // COURSES: Completed Courses
  if (completedClassId !== undefined) {
    dataToSave.completedCourseIds = Array.isArray(entry?.completedCourseIds) ? entry?.completedCourseIds : [];

    // De-dupe
    if (!dataToSave.completedCourseIds.includes(completedClassId)) {
      dataToSave.completedCourseIds.push(completedClassId);
    }
  }

  // COURSES: Data Clean Up: Remove Completed Class IDs
  if (Array.isArray(completedClassIdsToRemove) && Array.isArray(entry?.completedCourseIds)) {
    // Remove all Courses if their IDs are in completedClassIdsToRemove array
    const newCompletedClassIds = entry?.completedCourseIds.filter((id) => !completedClassIdsToRemove.includes(id));

    dataToSave.completedCourseIds = newCompletedClassIds.length === 0 ? null : newCompletedClassIds;
  }

  // COURSES: Completed Topics
  if (completedTopicId !== undefined) {
    dataToSave.completedCourseTopicIds = Array.isArray(entry?.completedCourseTopicIds) ? entry?.completedCourseTopicIds : [];

    // De-dupe
    if (!dataToSave.completedCourseTopicIds.includes(completedTopicId)) {
      dataToSave.completedCourseTopicIds.push(completedTopicId);
    }
  }

  // COURSES: Data Clean Up: Remove Completed Topic IDs
  if (Array.isArray(completedTopicIdsToRemove) && Array.isArray(entry?.completedCourseTopicIds)) {
    // Remove all Topics if their IDs are in completedTopicIdsToRemove array
    const newCompletedTopics = entry?.completedCourseTopicIds.filter((topicId) => !completedTopicIdsToRemove.includes(topicId));

    dataToSave.completedCourseTopicIds = newCompletedTopics.length === 0 ? null : newCompletedTopics;
  }

  // COURSES: Educational Unit IDs
  if (eUnitIds !== undefined) {
    dataToSave.educationUnitIds = Array.isArray(entry?.educationUnitIds) ? entry?.educationUnitIds : [];

    // De-dupe
    if (Array.isArray(eUnitIds)) {
      eUnitIds.forEach((id) => {
        if (!dataToSave.educationUnitIds.includes(id)) {
          dataToSave.educationUnitIds.push(id);
        }
      });
    }

    // Null
    if (eUnitIds === null) {
      dataToSave.eUnitIds = eUnitIds;
    }
  }

  // COURSES: Completion Certificates
  if (uniqueCertificateId !== undefined) {
    dataToSave.uniqueCertificateIds = Array.isArray(entry?.uniqueCertificateIds) ? entry?.uniqueCertificateIds : [];

    // De-dupe
    if (!dataToSave.uniqueCertificateIds.includes(uniqueCertificateId)) {
      dataToSave.uniqueCertificateIds.push(uniqueCertificateId);
    }
  }

  // COURSES: External Registration
  if (extRegClassId !== undefined) {
    dataToSave.externalRegisteredCourseIds = Array.isArray(entry?.externalRegisteredCourseIds) ? entry?.externalRegisteredCourseIds : [];

    // De-dupe
    if (!dataToSave.externalRegisteredCourseIds.includes(extRegClassId)) {
      dataToSave.externalRegisteredCourseIds.push(extRegClassId);
    }
  }

  // COURSES : Cohorts
  if (cohortId !== undefined || cohortIds !== undefined) {
    dataToSave.cohortIds = Array.isArray(entry?.cohortIds) ? entry?.cohortIds : [];

    if (cohortId !== undefined) {
      dataToSave.cohortIds = Array.isArray(entry?.cohortIds) ? entry?.cohortIds : [];

      // De-dupe
      if (!dataToSave.cohortIds.includes(cohortId)) {
        dataToSave.cohortIds.push(cohortId);
      }
    }

    // De-dupe
    if (Array.isArray(cohortIds)) {
      cohortIds.forEach((id) => {
        if (!dataToSave.cohortIds.includes(id)) {
          dataToSave.cohortIds.push(id);
        }
      });
    }

    // Null
    if (cohortIds === null) {
      dataToSave.cohortIds = cohortIds;
    }
  }

  // COURSES : Completion Certificates
  if (uniqueCertificateData !== undefined) {
    // if no integration key, create default structure
    dataToSave.integration = entry?.integration === undefined ? {} : entry?.integration;

    let newData = {};

    if (entry?.integration?.uniqueCertificateData === undefined) {
      // if uniqueCertificateData undefined, set to data
      newData = { ...uniqueCertificateData };
    } else {
      // has uniqueCertificateData, spread existing, add new from data
      newData = {
        ...entry.integration.uniqueCertificateData,
        ...uniqueCertificateData
      };
    }

    dataToSave.integration.uniqueCertificateData = newData;
  }

  // APPRENTICESHIPS
  if (apprenticeshipId !== undefined || apprenticeshipIds !== undefined) {
    // Mark user as apprentice (gather private data)
    //
    //
    // TODO Temporarily comment out for Demo
    //
    // dataToSave.isApprentice = true;
    //
    //

    dataToSave.apprenticeshipIds = Array.isArray(entry?.apprenticeshipIds) ? entry?.apprenticeshipIds : [];

    // De-dupe
    if (apprenticeshipId) {
      if (!dataToSave.apprenticeshipIds.includes(apprenticeshipId)) {
        dataToSave.apprenticeshipIds.push(apprenticeshipId);
      }
    }

    // De-dupe
    if (Array.isArray(apprenticeshipIds)) {
      apprenticeshipIds.forEach((id) => {
        if (!dataToSave.apprenticeshipIds.includes(id)) {
          dataToSave.apprenticeshipIds.push(id);
        }
      });
    }

    // Null
    if (apprenticeshipIds === null) {
      dataToSave.apprenticeshipIds = apprenticeshipIds;
    }
  }

  return dataToSave;
}

export default formatUser;
