import { SET_SIDE_SHEET, RESET_SIDE_SHEET } from './types';

export const setSideSheet = (sideSheet) => {
  document.body.classList.add('turbine-side-sheet-active');

  return (dispatch) => {
    dispatch({
      type: SET_SIDE_SHEET,
      sideSheet: {
        ...sideSheet
      }
    });
  };
};

export const resetSideSheet = () => {
  document.body.classList.remove('turbine-side-sheet-active');

  return (dispatch) => {
    dispatch({ type: RESET_SIDE_SHEET });
  };
};
