import * as twApi from './tw-api';

/**
 * Uploads a file to the user-data AWS bucket in
 * exchange for a URL.
 *
 * @param {string} prefix - The prefix/folder to upload the file into.
 * @param {File} file - The file to upload.
 * @param {string} organizationId - Organization Id
 * @returns {Promise<string>} - A URL that the uploaded file is accessible at.
 */

export async function uploadFile({
  prefix, file, fileExt, orgId
}) {
  const formData = new FormData();
  formData.append('file', file);

  const { url, id } = await twApi.post(
    '/v1/external/aws/upload',
    {
      ...(fileExt ? { fileExt } : {}),
      prefix,
      organizationId: orgId
    },
    formData
  );

  return {
    url,
    id
  };
}

/**
 * Move a previously uploaded file to another location.
 *
 * @param {} previousUrl - The "previous" / current URL of the file to move.
 * @param {*} newPrefix - The new prefix (path) that the file should be moved to (excluding its file name).
 * @param {string} organizationId - Organization Id
 * @returns {Promise<string>} - A URL that the copied file is accessible at.
 */
export async function moveFile({ previousUrl, newPrefix, orgId }) {
  const response = await twApi.post('/v1/external/aws/copy', {
    file: previousUrl,
    to: newPrefix,
    organizationId: orgId
  });

  return response.url;
}

// aws.moveFile({
//   previousUrl: url,
//   newPrefix: `${AWS_USER_PREFIX}/${organizationId}`,
//   organizationId
// });

/**
 * Deletes a previously uploaded file by its URL.
 *
 * @param {string} url - The URL of the uploaded file.
 * @param {string} organizationId - Organization Id
 * @returns {Promise<void>}
 */
export async function deleteFile({ url, orgId }) {
  await twApi.post('/v1/external/aws/delete-file', {
    file: url,
    organizationId: orgId
  });
}

/**
 * Delete previously uploaded files by their URLs.
 *
 * @param {string} urls - The URLs of the uploaded files.
 * @param {string} organizationId - Organization Id
 * @returns {Promise<void>}
 */
export async function deleteFiles({ urls, orgId }) {
  await twApi.post('/v1/external/aws/delete-files', {
    files: urls,
    organizationId: orgId
  });
}

/**
 * Delete previously uploaded files by their URLs.
 *
 * @param {string} file - The URL of the file
 * @param {string} name - The name you want the downloaded file to be
 * @param {string} organizationId - Organization Id
 * @returns {Promise<void>}
 */
export async function downloadFile({ file, name, orgId }) {
  const response = await twApi.post('/v1/external/aws/download', {
    file,
    name,
    organizationId: orgId
  });

  return response;
}
