import { isAdminOwnerSuperAdmin } from '@apprentage/utils';

// ROLES
export const isSuperAdmin = (role) => {
  return role.indexOf('superAdmin') !== -1;
};

export const isOwner = (role) => {
  return role.indexOf('owner') !== -1;
};

export const isAdmin = (role) => {
  return role.indexOf('admin') !== -1;
};

export const isTeacher = (role) => {
  return role.indexOf('teacher') !== -1;
};

export const isStudent = (role) => {
  return role.indexOf('student') !== -1;
};

export const getUserRole = ({ role }) => {
  switch (true) {
    case isSuperAdmin(role):
      return 'superAdmin';
    case isOwner(role):
      return 'owner';
    case isAdmin(role):
      return 'admin';
    case isTeacher(role):
      return 'teacher';
    default:
      return 'student';
  }
};

export const userOrgIsCourseOrg = ({ course, currentUser }) => {
  if (course.orgId !== currentUser.orgId) {
    return false;
  }

  return true;
};

export const userOrgInCourseOrgIds = ({ course, currentUser }) => {
  if (course.orgIds && course.orgIds.includes(currentUser.orgId)) {
    return true;
  }

  return false;
};

export const roleReadable = ({ role, orgType }) => {
  switch (true) {
    case isSuperAdmin(role):
      return 'Super Admin';
    case isOwner(role):
      return 'Owner';
    case isAdmin(role):
      return 'Admin';
    case isTeacher(role):
      return orgType !== 'workforce' ? 'Teacher' : 'Expert';
    default:
      return orgType !== 'workforce' ? 'Student' : 'User';
  }
};

// PERMISSIONS
export const canViewPdfPrintLink = (role) => {
  // role
  return isSuperAdmin(role);
};

export const canAccessByRole = (role, roles) => {
  function evalRoles(arrayItem) {
    // index, array
    return role.includes(arrayItem);
  }

  if (isOwner(role) || isSuperAdmin(role)) {
    return true;
  }
  if (roles === undefined) {
    return true;
  }
  if (roles.some(evalRoles)) {
    return true;
  }

  return false;
};

export const canViewRoleInTopNav = (role) => {
  return (
    isTeacher(role) || isAdmin(role) || isOwner(role) || isSuperAdmin(role)
  );
};

export const canAccessVelaApp = (role, enableWorkforceSuite) => {
  if (role === undefined || enableWorkforceSuite === undefined) {
    return false;
  }

  if (enableWorkforceSuite) {
    return true;
  }

  if (
    isStudent(role)
    || isTeacher(role)
    || isAdmin(role)
    || isOwner(role)
    || isSuperAdmin(role)
  ) {
    return true;
  }

  return false;
};

export const getMembershipReadable = (membership) => {
  // TODO switch this to one value in content model
  if (!membership) {
    return '';
  }

  if (!Array.isArray(membership)) {
    return '';
  }

  if (!membership.length) {
    return '';
  }

  switch (true) {
    case membership.includes('inactive'):
      return 'inactive';
    case membership.includes('pro'):
      return 'pro';
    case membership.includes('premium'):
      return 'premium';
    case membership.includes('starter'):
      return 'starter';
    default:
      return membership[0];
  }
};

export const canAccessVelaMaterials = (role, enableVelaMaterials) => {
  if (role === undefined || enableVelaMaterials === undefined) {
    return false;
  }

  if (!enableVelaMaterials) {
    return false;
  }

  return true;
};

export const canAccessLogbook = (role) => {
  if (role === undefined) {
    return false;
  }

  return (
    isStudent(role)
    || isTeacher(role)
    || isAdmin(role)
    || isOwner(role)
    || isSuperAdmin(role)
  );
};

export const canAccessDashboard = (role, organization) => {
  const { dashboard } = organization;

  if (!dashboard) return false;

  return (
    isTeacher(role) || isAdminOwnerSuperAdmin(role)
  );
};

export const canCreateResources = (role = [], orgType = '') => {
  if (!orgType) {
    return false;
  }

  if (isAdminOwnerSuperAdmin(role)) {
    return true;
  }

  if (isTeacher(role) && orgType === 'workforce') {
    return true;
  }

  return false;
};

export const canDeleteFile = (role = []) => {
  return isAdminOwnerSuperAdmin(role);
};

export const canEditFile = (role = [], orgType = '') => {
  if (!orgType) {
    return false;
  }

  if (isTeacher(role) && orgType === 'workforce') {
    return true;
  }

  return isAdminOwnerSuperAdmin(role);
};
