import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { canDeleteFile, canEditFile } from '../../../../services/currentUser';
import reactTableSortCaseInsensitive from '../../../../utils/reactTableSortCaseInsensitive';
import Table from './Table';
import NameCell from './NameCell';
import EditCell from './EditCell';
import BodyCell from './BodyCell';
import DateCell from '../../Tables/DateCell';

const CdnFilesList = ({
  data,
  contentId,
  contentType,
  deleteItem,
  editMenu,
  resourcePreview,
  hiddenColumns
}) => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // User
  const role = currentUser?.role || [];
  // Organization
  const orgType = organization?.type || '';

  const parentIds = useMemo(() => {
    return Array.isArray(data) ? data.map((item) => item?.parentId) : [];
  }, [data]);

  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      sortType: reactTableSortCaseInsensitive,
      Cell: (props) => (
        <NameCell {...props} resourcePreview={resourcePreview} />
      )
    },
    // {
    //   Header: 'URL',
    //   accessor: 'url',
    //   className: 'testing',
    //   disableSortBy: true,
    //   Cell: (props) => (
    //     <UrlCell {...props} />
    //   )
    // },
    {
      Header: 'Description',
      accessor: 'body',
      Cell: (props) => (
        <BodyCell {...props} />
      )
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      Cell: (props) => (
        <DateCell {...props} />
      )
    },
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      Cell: (props) => (
        <EditCell
          {...props}
          contentId={contentId}
          contentType={contentType}
          editMenu={editMenu}
          resourcePreview={resourcePreview}
          onClickDelete={deleteItem}
          canDelete={editMenu ? canDeleteFile(role) : undefined}
          canEdit={editMenu ? canEditFile(role, orgType) : undefined}
        />
      )
    }
  ], [editMenu, deleteItem, role, parentIds, hiddenColumns]);

  if (!data || (Array.isArray(data) && data.length === 0)) {
    return null;
  }

  return (
    <Table
      columns={columns}
      data={data}
      hiddenColumns={[
        ...hiddenColumns,
        ...(editMenu ? [] : ['id'])
      ]}
    />
  );
};

export default CdnFilesList;
