import React from 'react';
import { useSelector } from 'react-redux';
import LogbookRecord from '../LogbookRecord';

const LogbookRecords = ({ data, className = '' }) => {
  // Redux
  const apprenticeship = useSelector((state) => state.apprenticeship);

  if (!data || (Array.isArray(data) && data.length === 0)) {
    return null;
  }

  return (
    <div className={`LogbookRecords ${className}`}>
      {data.map((record) => {
        return (
          <LogbookRecord
            key={record?.id}
            data={record}
            apprenticeshipCompetencies={apprenticeship?.competencies}
          />
        );
      })}
    </div>
  );
};

export default LogbookRecords;
