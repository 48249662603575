const materialState = {
  title: null,
  body: null,
  userId: null,
  orgId: null,
  classId: null,
  topicId: null,
  integration: null,
  enableNewEditor: false,
  locationId: null,
  skillIds: null,
  promptText: null,
  promptType: null,
  resourceId: null,
  isDraft: null,
  resourceIds: null,
  fileUploadFlow: null,
  workflowType: null,
  id: null,
  createdAt: null,
  updatedAt: null
};

export default materialState;
