import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { withAuthorization } from '../../components/Session';
import { MATERIALS, SLASH } from '../../constants/routes';
import { canAccessVelaMaterials } from '../../services/currentUser';
import { DEFAULT_PAGINATION_LIMIT } from '../../constants/api';
import { goToElem } from '../../services/manageContent';
import Loading from '../../components/Loading';
import MaterialForm from '../../components/MaterialForm';
import PaginationSupabase from '../../components/PaginationSupabase';
import MyMaterials from '../../components/MyMaterials';
import WorkflowCards from '../../components/WorkflowsCards';

const Materials = () => {
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const materials = useSelector((state) => state.materials);
  // Current User
  const userId = currentUser?.id || '';
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || '';
  const enableVelaMaterials = organization?.enableVelaMaterials || false;
  // Search Params
  const searchParams = new URLSearchParams(window.location.search);
  const postNewMaterial = searchParams.get('postNewMaterial');
  const workflow = searchParams.get('workflow');

  // Local State
  const [showMaterialForm, setShowMaterialForm] = useState(false);

  const initialListConfig = {
    orgId,
    userId,
    limit: DEFAULT_PAGINATION_LIMIT,
    isDraft: true,
    page: 1
  };

  const [listConfig, setListConfig] = useState(initialListConfig);

  const materialFormCallback = () => {
    goToElem('TopNav');

    if (postNewMaterial) {
      history.replace(MATERIALS);
    }

    setShowMaterialForm(false);
  };

  const headerText = useMemo(() => {
    if (workflow === 'standup') {
      return 'What did you do yesterday?';
    }

    if (workflow === 'knowledge') {
      return 'Share an idea / workflow / process.';
    }

    if (workflow === 'decision') {
      return 'What decision has been made?';
    }

    return '';
  }, [workflow]);

  const headerSubtext = useMemo(() => {
    if (workflow === 'standup') {
      return 'What are you doing today?';
    }

    if (workflow === 'knowledge') {
      return '';
    }

    if (workflow === 'decision') {
      return 'What alternatives were considered?';
    }

    return '';
  }, [workflow]);

  const workflowReadable = useMemo(() => {
    if (workflow === 'standup') {
      return 'Daily Check-In';
    }

    if (workflow === 'knowledge') {
      return 'Knowledge Capture';
    }

    if (workflow === 'decision') {
      return 'Decision';
    }

    return '';
  }, [workflow]);

  const handleShowCreateRecordForm = useCallback(() => {
    if (postNewMaterial) {
      setShowMaterialForm(true);
    } else {
      history.push(`${MATERIALS}?postNewMaterial=true`);
    }

    setTimeout(() => {
      goToElem('RecordForm');
    }, 50);
  }, [postNewMaterial]);

  useEffect(() => {
    if (postNewMaterial === 'true') {
      handleShowCreateRecordForm();
    } else {
      setShowMaterialForm(false);
    }
  }, [postNewMaterial]);

  if (!currentUser.id || !organization.id) {
    return (
      <Loading
        className='position-relative'
        style={{ background: '#121212' }}
      />
    );
  }

  if (!canAccessVelaMaterials(role, enableVelaMaterials)) {
    return <Redirect to={SLASH} />;
  }

  if (!workflow && materials.fetched && !materials.list) {
    return (
      <div style={{ maxWidth: '345px', margin: 'auto' }}>
        <div
          className='Box mt-3 mb-3 px-2 rounded shadow'
          style={{ minHeight: 'auto' }}
        >
          <WorkflowCards />

          <div
            className='rounded py-3 text-center mt-3'
            style={{ backgroundColor: 'rgb(32, 32, 32)' }}
          >
            <p className='BoxText mb-1'>
              No Materials.
            </p>
            <div>
              Tap a workflow above to get started.
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (materials.fetched && showMaterialForm) {
    return (
      <div style={{ maxWidth: '345px', margin: 'auto' }}>
        <div
          className='Box mt-3 mb-3 px-2 rounded shadow'
          style={{ minHeight: 'auto' }}
        >
          <div className="mb-1">
            <div className="h6 badge text-uppercase font-weight-bold m-0 bg-dark text-white py-1 px-2 d-inline-block">
              {workflowReadable}
            </div>
          </div>
          <div
            className='BoxText'
            style={{ minHeight: 'auto' }}
          >
            {headerText}
          </div>

          {headerSubtext && (
            <div
              className='BoxText'
              style={{ minHeight: 'auto', fontSize: '1rem' }}
            >
              {headerSubtext}
            </div>
          )}
        </div>

        <div>
          <MaterialForm
            workflow={workflow}
            onSuccessCallback={materialFormCallback}
            onCancelCallback={materialFormCallback}
            onErrorCallback={materialFormCallback}
            className='mt-3'
          />
        </div>

      </div>
    );
  }

  return (
    <div style={{ maxWidth: '345px', margin: 'auto' }}>
      <div className='theme-dark position-relative'>
        <MyMaterials
          listConfig={listConfig}
        />

        {Array.isArray(materials.list) && materials.list.length > 0 && (
          <PaginationSupabase
            items={materials?.list}
            pagination={materials?.pagination}
            page={materials?.pagination?.page}
            onClickNext={() => {
              setListConfig((prev) => ({
                ...prev,
                page: materials?.pagination?.page + 1
              }));
              // goToElem('root');
            }}
            onClickPrev={() => {
              setListConfig((prev) => ({
                ...prev,
                page: materials?.pagination?.page - 1
              }));
            }}
          />
        )}
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(Materials);
