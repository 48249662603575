import React from 'react';
import { FaCheckCircle, FaCog } from 'react-icons/fa';
import { mdySimple } from '../../../../utils/date';

const DateCell = ({ column, row, value }) => {
  return (
    <div>
      <div>
        {value ? mdySimple(value) : '-'}
      </div>

      {row?.original?.isApproved && (
        <div
          className='d-flex'
        >
          <div
            className='mt-2 p-1 border-success badge border bg-success d-flex align-items-center'
          >
            <FaCheckCircle size={10} className='text-success' />
            <span className='small ml-1'>
              APPROVED
            </span>
          </div>
        </div>
      )}

      {!row?.original?.isApproved && column?.handleClickEdit && (
        <button
          className='btn btn-sm btn-outline-secondary mt-3'
          onClick={() => {
            column?.handleClickEdit(row?.original);
          }}
          type='button'
        >
          <span className='d-flex align-items-center'>
            <FaCog size={12} />
            <span className='ml-1'>
              Edit
            </span>
          </span>
        </button>
      )}
    </div>
  );
};

export default DateCell;
