import {
  SET_AUTH_ACTION,
  RESET_AUTH_ACTION,
  SET_AUTH_ALERT,
  RESET_AUTH_ALERT,
  SET_CURRENT_PAGE,
  SET_LOADING,
  LOGOUT
} from '../../actions/types';

const initialState = {
  authAction: null,
  authAlert: null,
  loading: false,
  currentPage: null
};

export default function app(state = initialState, action) {
  switch (action.type) {
    // case RESET_KEYS: {
    //   const keys = Object.fromEntries(action.keys);

    //   return {
    //     ...state,
    //     ...keys
    //     // [action.key]: initialState[action.key]
    //   };
    // }
    case SET_AUTH_ACTION:
      return {
        ...state,
        authAction: action.authAction
      };
    case RESET_AUTH_ACTION:
      return {
        ...state,
        authAction: null
      };
    case SET_AUTH_ALERT:
      return {
        ...state,
        authAlert: action.authAlert
      };
    case RESET_AUTH_ALERT:
      return {
        ...state,
        authAlert: null
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      };
    case LOGOUT:
      return {}; // ...initialState
    default:
      return state;
  }
}
