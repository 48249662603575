import {
  RESET_LOGBOOK_RECORD, SET_LOGBOOK_RECORD
} from '../../actions/types';
import logbookRecordState from '../initialState/logbookRecordState';

const initialState = {
  ...logbookRecordState,
  fetched: false
};

export default function logbookRecord(state = initialState, action) {
  switch (action.type) {
    case SET_LOGBOOK_RECORD:
      return {
        ...state,
        ...action.logbook,
        fetched: true
      };
    case RESET_LOGBOOK_RECORD:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
