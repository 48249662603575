import React, { useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';

const CompetenciesCollapse = ({
  competencyIds = [],
  competencies = {}
}) => {
  const [showCompetencies, setShowCompetencies] = useState(false);

  if ((!competencyIds) || (Array.isArray(competencyIds) && competencyIds.length === 0)) {
    return null;
  }

  return (
    <div
      className='mt-3 rounded px-2 py-1'
      style={{
        ...(showCompetencies ? {} : { backgroundColor: 'rgba(0, 0, 0, .25)' })
      }}
    >
      <div
        className={`d-flex align-items-center justify-content-between cursor-pointer ${showCompetencies && 'mb-2'}`}
        onClick={() => {
          setShowCompetencies(!showCompetencies);
        }}
      >
        <span className='d-flex align-items-center p-1'>
          <span className='small mr-2'>
            Competencies:
          </span>
          <span className='theme-dark d-flex'>
            <label
              className='badge badge-primary p-1 m-0'
            >
              {competencyIds.length}
            </label>
          </span>
        </span>
        <span
          className='mr-1'
        >
          {showCompetencies ? (
            <FaMinus className='text-white' />
          ) : (
            <FaPlus className='text-white' />
          )}
        </span>
      </div>

      {showCompetencies && (
        <div className='theme-dark'>
          {competencyIds.map((compId, i) => {
            const hasCompDesc = competencies && competencies[compId] !== undefined && competencies[compId]?.description;
            return (
              <div
                key={`${compId}-${i}`}
                className='px-2 py-1 rounded mr-3 mb-2 small'
                style={{
                  backgroundColor: 'rgba(0, 0, 0, .15)',
                  border: '1px rgba(0, 0, 0, .25) solid',
                  maxWidth: '300px',
                  overflow: 'hidden'
                }}
              >
                {hasCompDesc ? competencies[compId].description : compId}
              </div>
            );
          })}
        </div>
      )}

    </div>
  );
};

export default CompetenciesCollapse;
