import { GLOBAL_PLATFORM_NAME } from '@apprentage/constants';
import {
  SET_CURRENT_MODAL,
  RESET_CURRENT_MODAL
} from './types';
import { MODAL_KEY_SEND_EMAIL } from '../constants/modals';

export const setCurrentModal = (data) => {
  // document.body.classList.add('turbine-modal-active');

  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_MODAL,
      currentModal: {
        ...data,
        visible: true
      }
    });
  };
};

export const resetCurrentModal = () => {
  // document.body.classList.remove('turbine-modal-active');

  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_MODAL });
  };
};

export const openReportIssueModal = ({
  modalTitle = 'Report Issue',
  name = `${GLOBAL_PLATFORM_NAME} Support`,
  fromName, // userName
  replyTo, // userEmail
  orgName,
  message
}) => {
  return (dispatch) => {
    dispatch(setCurrentModal({
      key: MODAL_KEY_SEND_EMAIL,
      data: {
        modalTitle,
        name,
        fromName,
        message,
        subject: `⚠️ Issue Reported from ${orgName}`,
        hideSubject: true,
        disableSubject: true,
        replyTo,
        notifyTurbineEmail: true,
        notifyTurbineSlack: true
      }
    }));
  };
};
