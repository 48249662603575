import { toast } from 'react-toastify';
import { fetchSupabaseEntries, fetchSupabaseEntry, updateSupabaseEntry } from './supabaseProxy';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';

const table = 'apprenticeships';

/**
 * @param {array} ids
 * @param {string} orgId
 */
export const fetchApprenticeships = ({
  order = 'title',
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1,
  ids,
  orgId,
  employerId,
  instructionProviderId
}) => {
  return new Promise((resolve, reject) => {
    const config = {
      order,
      page,
      limit,
      orgId
    };

    if (employerId) {
      config['f.employerIds[ac]'] = employerId;
    }

    if (instructionProviderId) {
      config['f.instructionProviderIds[ac]'] = instructionProviderId;
    }

    if (orgId) {
      config['f.orgId[eq]'] = orgId;
    }

    if (ids) {
      config.ids = ids;
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    fetchSupabaseEntries(config, table).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`fetch (many) ${table}`, error);
      reject(error);
    });
  });
};

/**
 *
 * @param {string} id
 */
export const fetchApprenticeship = (id) => {
  return new Promise((resolve, reject) => {
    fetchSupabaseEntry({ table, id })
      .then((response) => {
        const apprenticeship = response || null;

        resolve(apprenticeship);
      })
      .catch((error) => {
        console.error(`fetch (single) ${table}`, error);
        reject(error);
      });
  });
};

/**
 *
 * @param {string} apprenticeshipId
 * @param {object} data
 */
export const updateApprenticeship = (data, id) => {
  return new Promise((resolve) => {
    updateSupabaseEntry({
      table,
      data,
      id
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`update ${table}`, error);
      });
  });
};
