import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import * as aws from '../../../services/aws';
import { AWS_USER_PREFIX } from '../../../constants/aws';
import { coerceToResourceName } from '../../../services/resources';

// REQUIRED props
// ***********************
// onImageUploadCallback @func
// orgId @str
// ************************

const ImageUploader = ({
  inputId = 'imageUploader',
  name,
  style,
  hideInputField,
  onImageUploadCallback,
  onImagePreviewCallback
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  // Misc
  const orgId = pathOr(null, ['id'], organization);

  const callback = ({ url, id }) => {
    if (onImageUploadCallback) {
      toast.success('Image uploaded!');

      onImageUploadCallback({ url, id });
    }
  };

  const preview = (blob) => {
    if (onImagePreviewCallback) {
      toast.info('Uploading image...');

      onImagePreviewCallback(blob);
    }
  };

  const onAddImageBlobHook = (e) => {
    const { files } = e.currentTarget;
    const blob = files[0];

    preview(blob);

    // TODO: support "image description" field for accessibility (https://github.com/nhn/tui.editor/issues/692)
    aws.uploadFile({
      prefix: `${AWS_USER_PREFIX}/${orgId}`,
      file: blob,
      orgId
    }).then(({ url, id }) => {
      const fileObj = { url, id };

      if (blob.name) {
        fileObj.name = coerceToResourceName({
          str: blob.name,
          fixCamelCase: true,
          fixHyphens: true,
          fixUnderScores: true
        });
      }
      if (blob.type) fileObj.type = blob.type;
      if (blob.size) fileObj.size = blob.size;

      toast.info('Optimizing image...');

      callback({ url, id });
    });
  };

  if (hideInputField || !orgId) {
    return null;
  }

  return (
    <input
      id={inputId}
      name={name || inputId}
      style={style}
      type="file"
      onChange={onAddImageBlobHook}
      accept="image/*"
    />
  );
};

export default ImageUploader;
