import {
  SET_ORG, RESET_ORG,
  SET_ORG_SKILLS,
  RESET_ORG_SKILLS
} from '../../actions/types';
import organizationState from '../initialState/organizationState';

const initialState = {
  ...organizationState,
  fetched: false,
  orgSkills: null, // { [id]: { difficulty: 4, name: '' }, ..}
  userGroupIds: null, // string[]
  courseGroupIds: null, // string[]
  resourceGroupIds: null, // string[]
  cdnFiles: null // [{}]
};

export default function organization(state = initialState, action) {
  switch (action.type) {
    case SET_ORG:
      return {
        ...state,
        ...action.organization,
        orgSkills: action.orgSkills,
        userGroupIds: action.userGroupIds,
        courseGroupIds: action.courseGroupIds,
        resourceGroupIds: action.resourceGroupIds,
        cdnFiles: action.cdnFiles,
        fetched: true
      };
    case SET_ORG_SKILLS:
      return {
        ...state,
        orgSkills: action.orgSkills
      };
    case RESET_ORG_SKILLS:
      return {
        ...state,
        orgSkills: initialState.orgSkills
      };
    case RESET_ORG:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
