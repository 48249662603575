import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaArrowCircleRight } from 'react-icons/fa';
import { MATERIALS } from '../constants/routes';
import { IMAGE_CARD_DECISION, IMAGE_CARD_KNOWLEDGE_TRANSFER, IMAGE_CARD_STANDUP } from '../constants/assets';
import DashboardCard from './DashboardCard';

const WorkflowCards = ({
  className,
  onClick,
  showMyMaterialsLink
}) => {
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);

  return (
    <>
      {organization?.enableVelaMaterials && (
        <div className={className}>
          <DashboardCard
            title="Daily Check-In"
            subTitle="Record progress, plans & roadblocks."
            onClick={() => {
              history.push(`${MATERIALS}?postNewMaterial=true&workflow=standup`);

              if (onClick) {
                onClick();
              }
            }}
            className='mb-3'
            imgUrl={IMAGE_CARD_STANDUP}
            imgPosition="top"
          />

          <DashboardCard
            title="Decision"
            subTitle="Document a decision for posterity."
            onClick={() => {
              history.push(`${MATERIALS}?postNewMaterial=true&workflow=decision`);

              if (onClick) {
                onClick();
              }
            }}
            className='mb-3'
            imgUrl={IMAGE_CARD_DECISION}
            imgPosition="top"
          />

          <DashboardCard
            title="Knowledge Capture"
            subTitle="Share ideas, workflows and processes."
            className='mb-3'
            onClick={() => {
              history.push(`${MATERIALS}?postNewMaterial=true&workflow=knowledge`);
              if (onClick) {
                onClick();
              }
            }}
            imgUrl={IMAGE_CARD_KNOWLEDGE_TRANSFER}
            imgPosition="center"
          />

          {showMyMaterialsLink && (
            <div
              className='rounded py-3 text-center mt-3'
              style={{ backgroundColor: 'rgb(32, 32, 32)' }}
            >
              <button
                onClick={() => {
                  history.push(MATERIALS);
                  if (onClick) {
                    onClick();
                  }
                }}
                type='button'
                className="btn-lg btn-link text-white"
              >
                <span className='d-flex align-items-center'>
                  <span className='mr-2'>
                    My Resources
                  </span>
                  <FaArrowCircleRight />
                </span>
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default WorkflowCards;
