import {
  RESET_CURRENT_MODAL, SET_CURRENT_MODAL
} from '../../actions/types';

const initialState = {
  key: '',
  visible: false,
  data: null
};

export default function currentModal(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_MODAL:
      return {
        ...state,
        ...action.currentModal
      };
    case RESET_CURRENT_MODAL:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
