import React from 'react';
import styled from 'styled-components';

const TurbineLoading = styled.div`
  align-items: center;
  background-color: #121212;
  // background-image: url('https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png');
  background-repeat: repeat;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  i {
    font-size: 2rem;
    margin-bottom: .5rem;
  }

  &.position-fixed {
    z-index: 100;
  }
`;

const Loading = ({ text, className = '', style }) => {
  return (
    <TurbineLoading
      className={`turbine-loading ${className}`}
      style={style}
    >
      <i
        className="fas fa-fan fa-spin large"
        aria-hidden="true"
        style={{ fontSize: '3rem' }}
      />
      <div className="text-capitalize">
        {text || 'Loading'}
      </div>
    </TurbineLoading>
  );
};

export default Loading;
