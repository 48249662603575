import React, { Component } from 'react';
import { toast } from 'react-toastify';
import * as aws from '../../../services/aws';
import { AWS_USER_PREFIX } from '../../../constants/aws';
import { coerceToResourceName } from '../../../services/resources';

// REQUIRED props
// ***********************
// onFileUploadCallback @func
// orgId @str
// accept @str
// ************************

class FileUploader extends Component {
  handleOnChange = (blob) => {
    const { onChange } = this.props;

    if (onChange) onChange(blob);
  }

  callback = (fileObj) => {
    const { hideToastOptimize } = this.props;

    if (!hideToastOptimize) {
      toast.info('Optimizing file...');
    }

    this.props.onFileUploadCallback(fileObj);
  }

  onFileUploadHook = (e) => {
    const {
      orgId,
      awsPrefix = AWS_USER_PREFIX
    } = this.props;
    const { files } = e.currentTarget;
    const blob = files[0];

    this.handleOnChange(blob);

    toast.info('Uploading file...');

    aws.uploadFile({
      prefix: `${awsPrefix}/${orgId}`,
      file: blob,
      orgId
    }).then(({ url, id }) => {
      const fileObj = { url, id };

      if (blob.name) {
        fileObj.name = coerceToResourceName({
          str: blob.name,
          fixCamelCase: true,
          fixHyphens: true,
          fixUnderScores: true
        });
      }

      if (blob.type) fileObj.type = blob.type;
      if (blob.size) fileObj.size = blob.size;

      // Add file as embed syntax in editor
      this.callback(fileObj);
    });
  }

  render() {
    const {
      id, name, style, accept
    } = this.props;
    const defaultId = 'fileUploader';

    return (
      <input
        data-cy="uploadFiles-input"
        id={id || defaultId}
        name={name || id || defaultId}
        style={style}
        type="file"
        onChange={this.onFileUploadHook}
        accept={accept || 'application/pdf, application/vnd.ms-excel'}
      />
    );
  }
}

export default FileUploader;
