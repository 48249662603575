export const serializeLogbooks = (logbooksList, apprenticeshipsList) => {
  if (
    Array.isArray(logbooksList)
    && logbooksList.length > 0
    && Array.isArray(apprenticeshipsList)
    && apprenticeshipsList.length > 0
  ) {
    const hash = {};

    apprenticeshipsList.forEach((apprenticeship) => {
      hash[apprenticeship?.id] = apprenticeship?.title;
    });

    const serializedLogbooks = logbooksList.map((logbook) => {
      const data = {
        ...logbook
      };

      if (hash[logbook?.apprenticeshipId]) {
        data.apprenticeshipTitle = hash[logbook?.apprenticeshipId] || logbook?.apprenticeshipId;
      }

      return data;
    });

    return serializedLogbooks;
  }
};
