import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// import { SpeechProvider } from '@speechly/react-client';
import { useDispatch } from 'react-redux';
import { useFlags, useFlagsmith, useFlagsmithLoading } from 'flagsmith/react';
import { FLAG_APP_VELA } from '../../constants/flagsmith';
import { setCurrentModal } from '../../actions/Modals';
import { MODAL_KEY_RELOAD } from '../../constants/modals';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import {
  getUserAppVersion,
  setUserAppVersion,
  usingLatestAppVersion
} from '../../services/organizations';
import heapAnalytics from '../../utils/heapAnalytics';
// Global Components
import TopNav from '../TopNav';
import Login from '../auth/Login';
import SSO from '../auth/SSO';
import Modals from '../Modals';
// Routes
import Slash from '../../routes/Slash';
import Logbook from '../../routes/Logbook';
import Logbooks from '../../routes/Logbooks';
import Records from '../../routes/Records';
// Styles
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/colors.css';
import '../../styles/cards.css';
import '../../styles/buttons.css';
import '../../styles/forms.css';
import '../../styles/tables.css';
import '../../styles/navs.css';
import '../../styles/modals.css';
import '../../styles/print.css';
import '../../styles/manageContent.css';
import '../../styles/timeline.css';
import '../../styles/themeDark.css';
import './style.css';
import Materials from '../../routes/Materials';
import Dashboard from '../../routes/Dashboard';
import SideSheets from '../SideSheets';

const App = () => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_APP_VELA]);
  const { getFlags } = useFlagsmith();
  const { isLoading } = useFlagsmithLoading();
  const userAppVersion = getUserAppVersion();
  const searchParams = new URLSearchParams(window.location.search);
  const postNewRecord = searchParams.get('postNewRecord');
  // const speechlyApiKey = process.env.REACT_APP_SPEECHLY_API_KEY;

  useEffect(() => {
    heapAnalytics.initialize();
  }, []);

  // FLAGS & REMOTE CONFIG
  useEffect(() => {
    if (!isLoading || postNewRecord === 'true') {
      getFlags();
    }

    if (flags?.app_vela?.enabled && flags?.app_vela?.value) {
      if (!userAppVersion) {
        setUserAppVersion(flags?.app_vela?.value);
      } else if (!usingLatestAppVersion(flags?.app_vela?.value)) {
        dispatch(setCurrentModal({
          key: MODAL_KEY_RELOAD,
          data: {
            appVersion: flags?.app_vela?.value
          }
        }));
      }
    }
  }, [
    getFlags,
    postNewRecord,
    flags?.app_vela,
    isLoading
  ]);
  // /FLAGS & REMOTE CONFIG

  return (
    // <SpeechProvider appId={speechlyApiKey}>
    <Router>
      <>
        <TopNav />
        {/* <div className="container-fluid px-0 px-sm-3"> */}
        <div className="container">
          <Switch>
            <Route exact path={ROUTES.SSO} component={SSO} />
            <Route exact path={ROUTES.LOGIN_ORG} component={Login} />
            <Route exact path={ROUTES.LOGIN_ORG} component={Login} />
            <Route path={ROUTES.LOGIN} component={Login} />
            <Route path={ROUTES.DASHBOARD} component={Dashboard} />
            <Route path={ROUTES.LOGBOOK} component={Logbook} />
            <Route path={ROUTES.LOGBOOKS} component={Logbooks} />
            <Route path={ROUTES.MATERIAL} component={Logbook} />
            <Route path={ROUTES.MATERIALS} component={Materials} />
            <Route path={ROUTES.RECORDS} component={Records} />
            <Route path={ROUTES.PROGRAMS} component={Slash} /> {/* Deprecated */}
            <Route path={ROUTES.PROGRAM} component={Slash} /> {/* Deprecated */}
            <Route exact path={ROUTES.SLASH} component={Slash} />
          </Switch>
        </div>
        <SideSheets />
        <Modals />
        <ToastContainer
          className="text-center"
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </>
    </Router>
    // </SpeechProvider>
  );
};

export default withAuthentication(App);
