import { fetchLocations } from '../services/locations';

import { SET_LOCATIONS } from './types';

export const getLocations = ({
  orgId,
  locationIds,
  include = 1,
  excludeDefaultLocation = false
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchLocations({
        orgId,
        locationIds,
        include,
        excludeDefaultLocation
      })
        .then((locations) => {
          dispatch({ type: SET_LOCATIONS, locations });
          resolve(locations);
        })
        .catch((error) => {
          console.error(error.message);
          reject(error);
        });
    });
  };
};
