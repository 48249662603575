import { fetchResources } from '../services/resources';
import { actionTypeFromParentType } from './CdnFiles';
// import { SET_ENTRY_ORG_ID } from './types';

export const getResourcesByParentId = (parentId, parentType = 'resourceGroup') => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!parentId) {
        console.error('Missing parameter, parentId is required!');
        reject(new Error('Missing parameter, parentId is required!'));
      }

      fetchResources({ parentId }).then((response) => {
        const list = response?.items;
        // ORG CHECK
        // dispatch({
        //   type: SET_ENTRY_ORG_ID,
        //   entryOrgId: list && list[0] ? list[0]?.orgId : null
        // });

        dispatch({
          type: actionTypeFromParentType({ parentType }),
          cdnFiles: list
        });
        resolve(list);
      });
    });
  };
};
