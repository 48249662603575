import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RecordState } from '../AudioReactRecorder';
import { getMaterials } from '../../actions/Materials';
import { dayMdyDate } from '../../utils/date';
import {
  createMaterial,
  deleteMaterial,
  updateMaterial
} from '../../services/materials';
import { createResource, updateResource } from '../../services/resources';
import { AWS_USER_PREFIX } from '../../constants/aws';
import { transcribeAudio } from '../../services/openApi';
import heapAnalytics from '../../utils/heapAnalytics';
import * as aws from '../../services/aws';
import RenderTinyMceEditor from '../ManageContent/Wysiwyg/RenderTinyMceEditor';
import AudioNotes from './AudioNotes';
import NoteTypeNav from './NoteTypeNav';
import MediaNotes from './MediaNotes';
import './style.css';

const generateBodySummaryTranscript = ({ body, summary, transcript }) => {
  let text = body || '';
  text = text.trim();

  if (!body && !summary && !transcript) {
    return null;
  }

  if (!text && summary) {
    text = `<p>${summary}</p>`;
  } else if (text && summary) {
    text += `<p><strong>Summary:</strong><br>${summary}</p>`;
  }

  if (transcript) {
    text += `<p><strong>Transcript:</strong><br>${transcript}</p>`;

    return text;
  }
};

const MaterialForm = ({
  className = '',
  workflow,
  showTitle = false,
  showEditTitle = false,
  onSuccessCallback,
  onCancelCallback,
  onErrorCallback,
  theme = 'dark'
}) => {
  const bodyRef = useRef(null);
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const userId = currentUser?.id || '';
  const locationId = currentUser?.locationId || '';
  // Modal Data
  const modalData = currentModal?.data || {};
  const material = modalData?.material || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(
    material?.title || dayMdyDate(new Date().toISOString())
  );
  const [editTitle, setEditTitle] = useState(showEditTitle);
  const [noteType, setNoteType] = useState('TEXT');
  const [bodyExceedMaxChars, setBodyExceedsMaxChars] = useState(false);
  const [recordState, setRecordState] = useState(RecordState.NONE);
  const [audioNotes, setAudioNotes] = useState([]);

  const createTrackingEvent = (eventName, data) => {
    heapAnalytics.track(eventName, {
      type: 'manual',
      ...data,
      orgId,
      userId,
      locationId,
      materialId: material?.id
    });
  };

  const updateEntry = (dataFromFields) => {
    const dataToSave = {
      ...dataFromFields
    };

    // Update Material
    createTrackingEvent('Material - Update - Preflight', {
      ...dataToSave,
      materialId: material?.id
    });

    updateMaterial(dataToSave, material?.id).then(() => {
      toast.success('Material updated!');

      createTrackingEvent('Material - Update - Success', {
        materialId: material?.id
      });

      dispatch(getMaterials({ userId, orgId }));

      if (onSuccessCallback && typeof onSuccessCallback === 'function') {
        onSuccessCallback();
      }
    });
  };

  const somethingWentWrong = (error) => {
    console.error(error);
    toast.error('Something went wrong, refresh and try again', {
      autoClose: false
    });

    if (onErrorCallback && typeof onErrorCallback === 'function') {
      onErrorCallback();
    }
  };

  const createEntry = (dataFromFields) => {
    const newMaterial = {
      ...dataFromFields,
      orgId,
      userId,
      isDraft: true,
      workflowType: workflow || null,
      locationId
    };

    toast.info('Processing...', { toastId: 'createMaterialInfo' });

    createTrackingEvent('Material - Create - Preflight', {
      ...newMaterial
    });

    createMaterial({
      ...newMaterial
    })
      .then((response) => {
        toast.dismiss('createMaterialInfo');
        toast.success('Material saved!');

        createTrackingEvent('Material - Create - Success', {
          materialId: response?.id
        });

        dispatch(getMaterials({ userId, orgId }));

        if (onSuccessCallback && typeof onSuccessCallback === 'function') {
          onSuccessCallback();
        }
      })
      .catch((error) => {
        createTrackingEvent('Error - Material - Create', {
          error: 'Problem creating Material'
        });
        somethingWentWrong(error);
      });
  };

  const onSave = () => {
    const dataFromFields = {
      title
    };

    if (!title || title.trim() === '') {
      toast.error('A title is required');
      return;
    }

    if (bodyRef && bodyRef.current && bodyRef.current.isDirty) {
      dataFromFields.body = bodyRef.current.getContent();
    }

    if (noteType === 'AUDIO' || (Array.isArray(audioNotes) && audioNotes.length > 0)) {
      handleSaveAudioNotes(dataFromFields);
    } else if (noteType === 'TEXT') {
      if (!dataFromFields.body || !dataFromFields.body.trim() === '') {
        toast.error('Material can not be empty.');
        return;
      }

      setLoading(true);

      if (material?.id) {
        updateEntry(dataFromFields);
      } else {
        createEntry(dataFromFields);
      }
    }
  };

  const audioMimeTypeExtMap = {
    'audio/wav': '.wav',
    'audio/mpeg': '.mp3',
    'audio/ogg': '.oga'
  };

  const handleSaveAudioNotes = (data) => {
    const audioFile = audioNotes[0];
    const duration = audioFile?.duration;
    const type = audioFile?.blob?.type;
    const size = audioFile?.blob?.size;
    const fileExt = audioMimeTypeExtMap[type];

    setLoading(true);

    createMaterial({
      ...data,
      promptType: 'audio',
      isDraft: true,
      fileUploadFlow: true,
      workflowType: workflow || null,
      orgId,
      userId,
      locationId
    }).then((responseMaterial) => {
      toast.info('Saving audio...', { toastId: 'savingAudio' });

      aws
        .uploadFile({
          prefix: `${AWS_USER_PREFIX}/${orgId}`,
          file: audioFile?.blob,
          fileExt,
          orgId
        })
        .then(({ url }) => {
          // ADD Resource
          const name = `Recording ${dayMdyDate(new Date().toISOString())}`;
          const dataToCreate = {
            name,
            url,
            size,
            duration,
            orgId,
            userId: currentUser?.id,
            userName: currentUser?.name,
            type,
            parentType: 'materials',
            parentId: responseMaterial?.id
          };

          createResource(dataToCreate).then((resource) => {
            updateMaterial(
              {
                resourceIds: [resource?.id]
              },
              responseMaterial?.id
            );

            toast.dismiss('savingAudio');
            toast.info('Transcribing audio...', { toastId: 'transcribingAudio', autoClose: false });
            transcribeAudio({
              orgId,
              url,
              includeSummary: true
            }).then((transcription) => {
              toast.dismiss('transcribingAudio');
              toast.success('Audio saved!');
              const summary = transcription?.summary || '';
              const transcript = transcription?.text || '';

              updateResource({
                body: summary || null,
                transcript: transcript || null
              }, resource?.id);

              const body = generateBodySummaryTranscript({
                body: data?.body,
                summary,
                transcript
              });

              updateMaterial(
                {
                  title: title || name,
                  body: body || null
                },
                responseMaterial?.id
              ).then(() => {
                setLoading(false);
                if (onSuccessCallback && typeof onSuccessCallback === 'function') {
                  onSuccessCallback();
                }
              });
            });
          });
        })
        .catch(() => {
          toast.error('Something went wrong');
          // TODO additional clean up
          deleteMaterial(responseMaterial?.id);
          setLoading(false);
        });
    });
  };

  return (
    <div
      id="MaterialForm"
      className={`MaterialForm ${theme ? 'theme-dark' : 'theme-light'} ${className}`}
    >
      {showTitle && (
        <div className="card-header py-3">
          <h5 className="font-weight-bold m-0 text-capitalize">
            {material?.id ? 'Edit' : 'Create'} Material
          </h5>
        </div>
      )}

      <div>
        <div
          className="mt-3 mb-2"
        >
          {editTitle ? (
            <input
              name="title"
              id="materialTitle"
              type="text"
              className="form-control"
              value={title}
              autoFocus
              onBlur={() => {
                setEditTitle(false);
              }}
              onChange={(e) => {
                const { value } = e.currentTarget;
                setTitle(value);
              }}
            />
          ) : (
            <h6
              className="border-bottom pt-2 pb-2 pl-2 border-dark"
              aria-hidden="true"
              onClick={() => {
                setEditTitle(true);
                // setTimeout(() => {
                //   document.getElementById('materialTitle').focus();
                // }, 100);
              }}
            >
              {title || 'Tap to enter a title'}
            </h6>
          )}
        </div>

        <NoteTypeNav
          setSelectedTab={setNoteType}
          selectedTab={noteType}
        />

        <div
          className="mt-3"
        >
          <RenderTinyMceEditor
            className={`${noteType === 'TEXT' ? 'd-block ' : 'd-none'}`}
            id="material-body"
            contentId={material?.id}
            contentType="material"
            height={250}
            autoFocus
            // uploadImgButton
            // uploadPdfButton
            // embedMediaButton
            defaultValue={material?.body}
            editorRef={bodyRef}
            onExceedMaxChars={(exceedsMaxCharsBool) => {
              setBodyExceedsMaxChars(exceedsMaxCharsBool);
            }}
          />

          <MediaNotes
            className={`${noteType === 'MEDIA' ? 'd-block my-3' : 'd-none'}`}
          />

          <AudioNotes
            className={`${noteType === 'AUDIO' ? 'd-block my-3' : 'd-none'}`}
            setAudioNotes={setAudioNotes}
            audioNotes={audioNotes}
            recordState={recordState}
            setRecordState={setRecordState}
          />
        </div>
      </div>

      {((noteType === 'AUDIO' && audioNotes?.length > 0 && (recordState === RecordState.STOP || recordState === RecordState.NONE)) || noteType === 'TEXT') && (
        <div className="card-footer py-3">
          <button
            className="btn btn-primary btn-md"
            type="submit"
            disabled={loading || bodyExceedMaxChars}
            onClick={onSave}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
          <button
            className="btn btn-link btn-md ml-2"
            type="button"
            disabled={loading}
            onClick={onCancelCallback}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default MaterialForm;
