import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';

const table = 'materials';

export const fetchMaterials = async ({
  orgId,
  userId,
  title,
  skillIds,
  isDraft,
  body,
  ids,
  order = '-createdAt',
  page = 1,
  select,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  const params = {
    order,
    page,
    limit
  };

  if (orgId) {
    params['f.orgId[eq]'] = orgId;
  }

  if (skillIds) {
    params['f.skillIds[ov]'] = skillIds.join(',');
  }

  if (title) {
    params['f.title[ilike]'] = title;
  }

  if (body) {
    params['f.body[ilike]'] = body;
  }

  if (isDraft) {
    params['f.isDraft[is]'] = isDraft;
  }

  if (userId) {
    params['f.userId[eq]'] = userId;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchMaterialsByTitle = ({
  title,
  orgId
}) => {
  return new Promise((resolve, reject) => {
    fetchMaterials({
      orgId,
      title,
      select: ['id', 'title', 'skillIds', 'createdAt']
    })
      .then((response) => {
        resolve({ materials: response?.items });
      })
      .catch((error) => {
        console.error(`fetch by title ${table}`, error);
        reject(error);
      });
  });
};

export const fetchMaterialsByBody = ({
  body,
  orgId
}) => {
  return new Promise((resolve, reject) => {
    fetchMaterials({
      orgId,
      body,
      select: ['id', 'title', 'skillIds', 'createdAt']
    })
      .then((response) => {
        resolve({ materials: response?.items });
      })
      .catch((error) => {
        console.error(`fetch by body ${table}`, error);
        reject(error);
      });
  });
};

export const fetchMaterialsBySkillIds = ({
  skillIds,
  orgId
}) => {
  return new Promise((resolve, reject) => {
    fetchMaterials({
      orgId,
      skillIds,
      select: ['id', 'title', 'skillIds', 'createdAt']
    })
      .then((response) => {
        resolve({ materials: response?.items });
      })
      .catch((error) => {
        console.error(`fetch by skillIds ${table}`, error);
        reject(error);
      });
  });
};

export const fetchMaterial = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateMaterial = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`update ${table}`, error);
      reject(error);
    });
  });
};

export const createMaterial = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`create ${table}`, error);
      reject(error);
    });
  });
};

export const deleteMaterial = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`delete ${table}`, error);
      reject(error);
    });
  });
};
