import React from 'react';
// import styled from 'styled-components';
// import { ICON_PLUS } from '@apprentage/constants';
import createImageFromInitials from '../../utils/createImageFromInitials';
import './style.css';

const PersonActivityCard = ({
  profilePicture = '',
  name,
  personRole
  // nextActions = '<Display Next Action to Take>'
}) => {
  // const PlusButton = styled.button`
  //   background: #8e44ad;
  //   z-index: 50;
  //   bottom: 0;
  //   border-radius: 100%;
  //   height: 30px;
  //   width: 30px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   cursor: pointer;
  //   right: 0;
  //   position: absolute;
  //   text-align: center;

  //   i {
  //     font-size: 1rem;
  //   }
  // `;
  return (
    <div className="person-activity-card">
      <div
        className="p-3 pb-0 person-activity-card-once position-relative"
        style={{
          borderRadius: '5px 5px 0 0'
        }}
      >
        <div
          className="d-flex person-profile-image-container position-absolute mt-1"
        >
          <img
            src={profilePicture || createImageFromInitials(name, '#ffffff')}
            className="overflow-hidden rounded-circle"
            alt="profile"
          />
          {/* <PlusButton
            className="btn btn-sm"
            onClick={() => { }}
            role="button"
          >
            <i className={`${ICON_PLUS} text-white`} />
          </PlusButton> */}
        </div>
      </div>
      <div
        className="px-3 pb-3 person-activity-nameblock text-light"
        style={{
          borderRadius: '0 0 5px 5px'
        }}
      >
        <div
          className="d-flex justify-content-center flex-column align-items-center mt-2"
        >
          <div
            className="h5 mb-0 text-center"
          >
            {name}
          </div>

          {personRole && (
            <div
              className="mt-2"
            >
              {personRole}
            </div>
          )}
        </div>

        {/* <div
          className="d-flex justify-content-center align-items-around flex-row mx-auto mt-2 mt-md-4"
        >
          <div className="pr-3">
            <div className="h3 text-center">{activitiesCount}</div>
            <div>Activities</div>
          </div>
          <div className="pr-3">
            <div className="h3 text-center">{actionsToTakeCount}</div>
            <div>Actions to take</div>
          </div>
          <div className="pr-3">
            <div className="h3 text-center">{stepsToCompleteCount}</div>
            <div>Steps to complete</div>
          </div>
          <span className="h5">{nextActions}</span>
        </div> */}
      </div>
    </div>
  );
};

export default PersonActivityCard;
