import { toast } from 'react-toastify';
import { VELA_MARKETING_SITE } from '../constants/urls';
import * as twApi from './tw-api';
import { GMAIL_SEND } from '../constants/api';
import { PLATFORM_NAME } from '../constants/globals';
import { fetchSupabaseEntries } from './supabaseProxy';

export const sendEmail = ({
  subject,
  message,
  replyTo,
  to,
  toName,
  fromName,
  orgName
}) => {
  return new Promise((resolve, reject) => {
    const emailParts = {
      to,
      from: {
        name: fromName || orgName
      },
      replyTo,
      subject,
      body: '',
      htmlBody: `
        <h1>
          ${orgName}
        </h1>
        <p>
          Hi ${toName},
        </p>
        <p>
          ${message}
        </p>
        <p>
          Best,
          <br />
          ${fromName || orgName}
        </p>
        <br />
        <br />
        <font color='#888888'>--</font>
        <br />
        <p>
          Powered by <a href="${VELA_MARKETING_SITE}?track=sendEmail" target="_blank" title="${PLATFORM_NAME}">${PLATFORM_NAME}</a> a Turbine Workforce company.
        </p>
      `
    };

    return twApi.post(GMAIL_SEND, '', JSON.stringify(emailParts)).then((response) => {
      if (response && response.status === 200) {
        resolve();
      } else {
        toast.error(response.message); // ex: 'Something went wrong, try again'
        console.error(response.status); // ex: 'error_invitation_fail'

        reject({
          message: response.message,
          status: response.status
        });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
};

export const fetchUser = async ({ userId, select }) => {
  const errorUserIdRequired = 'userId is required';

  if (!userId) {
    console.error(errorUserIdRequired);
    throw new Error(errorUserIdRequired);
  }

  try {
    const params = { id: userId };

    if (Array.isArray(select) && select.length) {
      params.select = select.join(',');
    }

    const response = await fetchSupabaseEntries(params, 'users');
    const items = response?.items;
    const user = Array.isArray(items) && items[0] !== undefined ? items[0] : null;
    return user;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
