import sanitize from 'sanitize-filename';
import { pathOr } from 'ramda';
import { v4 as uuid } from 'uuid';
import {
  ICON_FILE,
  ICON_FILE_EXCEL,
  ICON_FILE_IMAGE,
  ICON_FILE_PDF,
  ICON_LINK,
  ICON_RESOURCE
} from '@apprentage/constants';
import * as aws from './aws';

import { client, flattenItems } from './contentful';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import { summarizeDoc } from './openApi';
import {
  coerceWhiteSpacesToSingle,
  unCamelCase,
  hyphenToSpace,
  underscoreToSpace,
  plusToSpace
} from '../utils/filenames';

const table = 'resources';

export const fileType = (type = '') => {
  switch (type) {
    case 'link':
      return {
        name: 'link',
        icon: ICON_LINK
      };
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
      return {
        name: 'image',
        icon: ICON_FILE_IMAGE
      };
    case 'application/pdf':
      return {
        name: 'excel',
        icon: ICON_FILE_PDF
      };
    case 'application/vnd.ms-excel':
      return {
        name: 'excel',
        icon: ICON_FILE_EXCEL
      };
    case 'video/mp4':
      return {
        name: 'video',
        icon: ICON_RESOURCE
      };
    default:
      return {
        name: 'file',
        icon: ICON_FILE
      };
  }
};

/**
 *
 * @param {number} size in bytes
 */
export const getFileSize = (size) => {
  const kb = size / 1024;
  const mb = kb / 1024;
  const gb = mb / 1024;
  if (kb < 1024) {
    return `${Math.round(kb)} kB`;
  }
  if (mb < 1024) {
    return `${Math.round(mb)} mB`;
  }
  return `${Math.round(gb)} gB`;
};

export const downloadResource = ({ name, file, orgId }) => {
  return new Promise((resolve, reject) => {
    aws.downloadFile({
      file,
      name,
      orgId
    }).then((response) => {
      const url = pathOr(null, ['url'], response);
      resolve(url);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchResources = async ({
  orgId,
  parentId,
  name,
  body,
  ids,
  order = 'name',
  select,
  limit = 1000
}) => {
  const params = {
    order,
    limit
  };

  if (orgId) {
    params['f.orgId[eq]'] = orgId;
  }

  if (parentId) {
    params['f.parentId[eq]'] = parentId;
  }

  if (name) {
    params['f.name[ilike]'] = name;
  }

  if (body) {
    params['f.body[ilike]'] = body;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchResource = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateResource = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    }).then((updatedResource) => {
      resolve(updatedResource);
    }).catch((error) => {
      console.error('updateResource', error);
      reject(error);
    });
  });
};

export const createResource = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    }).then((newResource) => {
      resolve(newResource);
    }).catch((error) => {
      console.error('updateResource', error);
      reject(error);
    });
  });
};

export const deleteResource = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    }).then((deletedResource) => {
      resolve(deletedResource);
    }).catch((error) => {
      console.error('deleteResource', error);
      reject(error);
    });
  });
};

export const fetchResourcesByTitle = ({ orgId, name }) => {
  return new Promise((resolve, reject) => {
    fetchResources({
      orgId,
      name
    }).then((response) => {
      resolve({ cdnFiles: response?.items });
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchResourcesByBody = ({ orgId, body }) => {
  return new Promise((resolve, reject) => {
    fetchResources({
      orgId,
      body
    }).then((response) => {
      resolve({ cdnFiles: response?.items });
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const filesInGroupsMatchingSearch = ({ searchValue, groups }) => {
  const files = [];
  const filenameLowerCase = searchValue.toLowerCase();

  groups.forEach((group) => {
    if (group.cdnFileNames) {
      group.cdnFileNames.forEach((cdnFilename) => {
        const cdnFilenameLowerCase = cdnFilename.toLowerCase();

        if (cdnFilenameLowerCase.includes(filenameLowerCase)) {
          const fileId = cdnFilename.split(':')[0];
          const file = group.integration.cdnFiles.find((f) => f.id === fileId);

          if (file) {
            files.push(file);
          }
        }
      });
    }
  });

  return files.length ? files : null;
};

// TODO this needs to change to hit Supabase Resources
export const fetchGroupsByFilename = ({ orgId, searchValue }) => {
  return new Promise((resolve, reject) => {
    const config = {
      content_type: 'group',
      'fields.cdnFileNames[match]': searchValue,
      'fields.orgId': orgId
    };

    return client.getEntries(config).then(({ items }) => {
      const resourceGroups = items.length ? flattenItems(items) : null;
      const cdnFiles = resourceGroups ? filesInGroupsMatchingSearch({ searchValue, groups: resourceGroups }) : null;

      resolve({
        value: searchValue,
        groups: resourceGroups,
        cdnFiles
      });
    }).catch((error) => {
      reject(error);
    });
  });
};

// TODO this needs to change to hit Supabase Resources
export const generateUniqueResourceId = async ({ orgId }) => {
  const uniqueId = uuid();
  const response = await fetchGroupsByFilename({ orgId, searchValue: uniqueId });
  const cdnFiles = pathOr([], ['cdnFiles'], response);

  if (cdnFiles.length > 0) {
    generateUniqueResourceId({ orgId });
  } else {
    return uniqueId;
  }
};

export const coerceToResourceName = ({
  str,
  fixCamelCase = false,
  fixHyphens = false,
  fixUnderScores = false,
  fixPlus = false
}) => {
  let output = str;

  output = sanitize(output);

  if (fixCamelCase) {
    // This flag is needed to prevent unexpected UX
    // while manipulating the str in an input field
    output = unCamelCase(output);
  }

  if (fixHyphens) {
    output = hyphenToSpace(output);
  }

  if (fixUnderScores) {
    output = underscoreToSpace(output);
  }

  // output = fileNameFriendly(output);

  // output = adhereToOSFilenames(output);
  if (fixPlus) {
    output = plusToSpace(output);
  }

  output = coerceWhiteSpacesToSingle(output);

  return output.trimEnd();
};

export const summarizeFile = ({
  topic,
  url = '',
  rawText = '',
  orgId = '',
  outputCharLength = 200,
  // 300 > 445
  // 250 > 300
  // 200 > 245
  createPreSignedUrl = false
}) => {
  return new Promise((resolve, reject) => {
    summarizeDoc({
      ...(topic ? { topic } : {}),
      ...(url ? { url } : {}),
      ...(createPreSignedUrl ? { createPreSignedUrl } : {}),
      ...(rawText ? { rawText } : {}),
      organizationId: orgId,
      outputCharLength
    }).then((response) => {
      resolve(response?.data);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const previewFileTypes = (type) => {
  const acceptableFileTypes = [
    'application/pdf',
    'video/mp4'
  ];

  if (!type) {
    return false;
  }

  if (acceptableFileTypes.includes(type)) {
    return true;
  }

  if (type.includes('image') && type !== 'image/vnd.adobe.photoshop') {
    // 'image/jpg', 'image/jpeg', 'image/png', 'image/gif'
    return true;
  }

  return false;
};
