import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { canViewRoleInTopNav, roleReadable } from '../../../services/currentUser';
import ProfilePicture from '../../ManageContent/ProfilePicture';

const TopNavEmail = styled.small`
  display: block;
  max-width: 276px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TopNavUserDetails = styled.div`
  min-width: 250px;
  position: relative;
`;

const TopNavUserRole = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
  display: flex;
  flex-flow: column;
`;

const UserDetails = ({ children }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);

  if (!currentUser.id || !organization.id) {
    return null;
  }

  const { type: orgType } = organization;

  const {
    email,
    role,
    testUser,
    name
  } = currentUser;

  return (
    <TopNavUserDetails
      className="py-3 px-4 text-center"
    >
      <TopNavUserRole>
        {canViewRoleInTopNav(role) && (<span className="badge badge-secondary mb-1">{ roleReadable({ role, orgType }) }</span>)}
        {testUser && (<div className="badge badge-warning">Test User</div>)}
      </TopNavUserRole>

      <ProfilePicture
        className="d-flex align-items-center justify-content-center"
        height="80"
        width="80"
      />

      <div className="text-nowrap">
        {name}
      </div>
      <TopNavEmail className="text-nowrap mb-1">
        {email}
      </TopNavEmail>
      {children && (
        <Fragment>
          {children}
        </Fragment>
      )}
    </TopNavUserDetails>
  );
};

export default UserDetails;
