import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RELOAD_IMG } from '@apprentage/constants';
import Modal from '../../Modal';
import { setUserAppVersion } from '../../../services/organizations';
import { PLATFORM_NAME } from '../../../constants/globals';
// import { version } from '../../../../package.json';

const Reload = () => {
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Modal
  const appVersion = currentModal?.data?.appVersion;
  // Local State
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      visible={currentModal.visible}
      pos="top"
    >
      <div className="reload-container text-center">
        <div>
          <img
            src={RELOAD_IMG}
            className="w-100 mb-4"
            alt="Reload"
          />

          <h4 className='font-weight-bold'>
            {PLATFORM_NAME} v.{appVersion} is available!
          </h4>

          <p>
            To use the latest version click continue.
          </p>

          <div className="w-100 pb-3">
            <button
              className="btn btn-md btn-primary m-auto d-block"
              title="Update Version"
              disabled={loading}
              onClick={() => {
                setLoading(true);
                setUserAppVersion(appVersion);
                setTimeout(() => {
                  window.location.reload();
                }, 250);
              }}
              type="button"
            >
              {loading ? 'Processing...' : 'Continue'}
            </button>
          </div>

        </div>
      </div>
    </Modal>
  );
};

export default Reload;
