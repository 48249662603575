import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { fetchUserByUid } from '../../services/user';
import {
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABASE_URL,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID
} from '../../constants/firebase';

const config = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: REACT_APP_DATABASE_URL,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();
    this.ref = app.database().ref();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    // this.facebookProvider = new app.auth.FacebookAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithCustomToken = (token) =>
    this.auth.signInWithCustomToken(token);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  // doSignInWithFacebook = () =>
  //   this.auth.signInWithPopup(this.facebookProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // TODO Merge Auth and DB User API

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        let count = 0;
        let userExistsContentful = setInterval(() => {
          console.log('onAuthStateChanged', count);

          // TODO refactor using recursive pattern
          fetchUserByUid(authUser.uid).then((response) => {
            if (response?.id) {
              // Login
              userExistsContentful = clearInterval(userExistsContentful);

              next({ authUser, currentUser: response });
            } else {
              // New Member Registration (User not in Contentful yet)
              count += 1;

              if (count >= 10) {
                userExistsContentful = clearInterval(userExistsContentful);
                // TODO stop loading
                console.error('CM:Account creation error');
                fallback();
              }
            }
          });
        }, 1000);
      } else {
        fallback();
      }
    });

  // *** User API ***

  // user = uid => this.db.ref(`users/${uid}`);
  //
  // users = () => this.db.ref('users');

  // *** Message API ***

  // message = uid => this.db.ref(`messages/${uid}`);
  //
  // messages = () => this.db.ref('messages');
}

export default Firebase;
