// APPRENTICESHIPS
export const SET_APPRENTICESHIPS = 'SET_APPRENTICESHIPS';
export const RESET_APPRENTICESHIPS = 'RESET_APPRENTICESHIPS';
export const SET_APPRENTICESHIP = 'SET_APPRENTICESHIP';
export const RESET_APPRENTICESHIP = 'RESET_APPRENTICESHIP';

// EMPLOYERS (LOCATIONS)
export const SET_EMPLOYERS = 'SET_EMPLOYERS';
export const RESET_EMPLOYERS = 'RESET_EMPLOYERS';
export const SET_EMPLOYER = 'SET_EMPLOYER';
export const RESET_EMPLOYER = 'RESET_EMPLOYER';
export const SET_EMPLOYER_PROFILE = 'SET_EMPLOYER_PROFILE';
export const RESET_EMPLOYER_PROFILE = 'RESET_EMPLOYER_PROFILE';
export const SET_EMPLOYER_PROFILES = 'SET_EMPLOYER_PROFILES';
export const RESET_EMPLOYER_PROFILES = 'RESET_EMPLOYER_PROFILES';

// LOGBOOK (supabase)
export const SET_LOGBOOK = 'SET_LOGBOOK';
export const RESET_LOGBOOK = 'RESET_LOGBOOK';
// LOGBOOK HOURS (supabase)
export const SET_LOGBOOK_HOURS = 'SET_LOGBOOK_HOURS';
export const RESET_LOGBOOK_HOURS = 'RESET_LOGBOOK_HOURS';
// LOGBOOK RECORD (supabase)
export const SET_LOGBOOK_RECORD = 'SET_LOGBOOK_RECORD';
export const RESET_LOGBOOK_RECORD = 'RESET_LOGBOOK_RECORD';
// LOGBOOK RECORDS (supabase)
export const SET_LOGBOOK_RECORDS = 'SET_LOGBOOK_RECORDS';
export const RESET_LOGBOOK_RECORDS = 'RESET_LOGBOOK_RECORDS';
// LOGBOOKS (supabase)
export const SET_LOGBOOKS = 'SET_LOGBOOKS';
export const RESET_LOGBOOKS = 'RESET_LOGBOOKS';

// MATERIAL (supabase)
export const SET_MATERIAL = 'SET_MATERIAL';
export const RESET_MATERIAL = 'RESET_MATERIAL';
// MATERIALS (supabase)
export const SET_MATERIALS = 'SET_MATERIALS';
export const RESET_MATERIALS = 'RESET_MATERIALS';

// Material Files (aws)
export const SET_MATERIAL_FILES = 'SET_MATERIAL_FILES';
export const RESET_MATERIAL_FILES = 'RESET_MATERIAL_FILES';

// MEMBERS
export const SET_USERS = 'SET_USERS';

// Current User (contentful)
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// User (firebase)
export const SET_USER = 'SET_USER';

export const SET_CURRENT_ENTRY = 'SET_CURRENT_ENTRY';
export const RESET_CURRENT_ENTRY = 'RESET_CURRENT_ENTRY';

// AUTH
export const SET_AUTH_ACTION = 'SET_AUTH_ACTION';
export const RESET_AUTH_ACTION = 'RESET_AUTH_ACTION';
export const SET_AUTH_ALERT = 'SET_AUTH_ALERT';
export const RESET_AUTH_ALERT = 'RESET_AUTH_ALERT';

// App
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_LOADING = 'SET_LOADING';
export const LOGOUT = 'LOGOUT';

// MODALS
export const SET_CURRENT_MODAL = 'SET_CURRENT_MODAL';
export const RESET_CURRENT_MODAL = 'RESET_CURRENT_MODAL';

// SIDE SHEETS
export const SET_SIDE_SHEET = 'SET_SIDE_SHEET';
export const RESET_SIDE_SHEET = 'RESET_SIDE_SHEET';

// RESOURCE : SEARCH
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_SEARCH = 'SET_SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';

// ORGANIZATION
export const SET_ORG = 'SET_ORG';
export const SET_SYNDICATED_COURSE_ORGS = 'SET_SYNDICATED_COURSE_ORGS';
export const RESET_SYNDICATED_COURSE_ORGS = 'RESET_SYNDICATED_COURSE_ORGS';

export const SET_ORG_SKILLS = 'SET_ORG_SKILLS';
export const RESET_ORG_SKILLS = 'RESET_ORG_SKILLS';
export const RESET_ORG = 'RESET_ORG';

// LOCATIONS
export const SET_LOCATION = 'SET_LOCATION';
export const RESET_LOCATION = 'RESET_LOCATION';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const RESET_LOCATIONS = 'RESET_LOCATIONS';

export const SET_LOCATIONS_WEATHER_CURRENT = 'SET_LOCATIONS_WEATHER_CURRENT';
export const RESET_LOCATIONS_WEATHER_CURRENT = 'RESET_LOCATIONS_WEATHER_CURRENT';

// Invites
export const SET_INVITES = 'SET_INVITES';
export const RESET_INVITES = 'RESET_INVITES';
