import React from 'react';
import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
// import CodeBlock from './CodeBlock';
import './tinymce.css';
/**
 * Wrapper component for the `react-markdown` dependency - renders
 * markdown strings on the page.
 *
 * @param {} props
 * @param {string} props.source - The source markdown text to render.
 * @param {bool} props.enableNewEditor - The source markdown text to render.
 */
const RenderMarkdown = ({
  source,
  enableNewEditor
}) => {
  // useEffect(() => {
  //   if (source && source.indexOf('<pre') > -1) {
  //     window.Prism.highlightAll();
  //   }
  // }, [source]);

  if (enableNewEditor) {
    return (
      <div className="mce-content-body">
        {source ? parse(source) : ''}
      </div>
    );
  }

  return (
    <ReactMarkdown
      source={source}
      escapeHtml={false}
      // renderers={{ code: CodeBlock }}
      plugins={[breaks]}
    // linkTarget='_blank'
    />
  );
};

export default RenderMarkdown;
