import { toast } from 'react-toastify';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';

const table = 'logbookRecords';

/**
 * @param {Array} ids
 * @param {String} orgId
 */
export const fetchLogbookRecords = ({
  ids = [],
  limit = 50,
  page = 1,
  order = '-date',
  logbookId = undefined,
  isApproved = undefined,
  gteCreatedAt = undefined,
  userId = undefined,
  apprenticeshipId = undefined,
  locationId = undefined,
  orgId = undefined
}) => {
  return new Promise((resolve, reject) => {
    const config = {
      order,
      page,
      limit
    };

    if (gteCreatedAt !== undefined) {
      config['f.createdAt[gte]'] = gteCreatedAt; // '2023-09-06T02:11:52.844Z'
    }

    if (isApproved !== undefined) {
      config['f.isApproved[is]'] = isApproved;
    }

    if (apprenticeshipId !== undefined) {
      config['f.apprenticeshipId[eq]'] = apprenticeshipId;
    }

    if (logbookId !== undefined) {
      config['f.logbookId[eq]'] = logbookId;
    }

    if (locationId !== undefined) {
      config['f.locationId[eq]'] = locationId;
    }

    if (userId !== undefined) {
      config['f.userId[eq]'] = userId;
    }

    if (orgId !== undefined) {
      config['f.orgId[eq]'] = orgId;
    }

    if (ids.length) {
      config.ids = ids.join(',');
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    fetchSupabaseEntries(config, table)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`fetch ${table}`, error);
        reject(error);
      });
  });
};

/**
 *
 * @param {string} id
 */
export const fetchLogbookRecord = (id) => {
  return new Promise((resolve, reject) => {
    fetchSupabaseEntry({ table, id })
      .then((response) => {
        const logbook = response || null;

        resolve(logbook);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const createLogbookRecord = (data) => {
  return new Promise((resolve) => {
    createSupabaseEntry({ data, table })
      .then((response) => {
        const id = response?.id || null;

        resolve(id);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`create ${table}`, error);
      });
  });
};

/**
 *
 * @param {string} id
 * @param {object} data
 */
export const updateLogbookRecord = (data, id) => {
  return new Promise((resolve) => {
    updateSupabaseEntry({
      table,
      data,
      id
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`update ${table}`, error);
      });
  });
};

/**
 *
 * @param {string} id
 * @param {object} data
 */
export const deleteLogbookRecord = (id) => {
  return new Promise((resolve) => {
    deleteSupabaseEntry({
      data: { id },
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error(`delete ${table}`, error);
    });
  });
};

export const recordsCompetencyHoursEnum = ({ records }) => {
  const competencyHoursEnum = {};

  records.forEach((record) => {
    if (record?.competencyIds?.length) {
      record.competencyIds.forEach((competencyId) => {
        if (competencyHoursEnum[competencyId] === undefined) {
          competencyHoursEnum[competencyId] = 0;
        }

        competencyHoursEnum[competencyId] += record.hours;
      });
    }
  });

  return competencyHoursEnum;
};
