import { pathOr } from 'ramda';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const OrgLocation = ({
  className = '',
  locationId,
  small = false,
  imageHeight = 20
}) => {
  // redux
  const locations = useSelector((state) => state.locations);
  // misc
  const orgLocation = useMemo(() => {
    return locations.list ? locations.list.find(({ id }) => locationId === id) : null;
  }, [locationId]);
  const locationImage = pathOr(null, ['locationImage'], orgLocation);
  const locationName = pathOr(null, ['name'], orgLocation);

  if (orgLocation?.id) {
    return (
      <div className={className}>
        <div
          className="text-muted m-0 d-flex align-items-center"
        >
          {locationImage && (
            <img
              src={locationImage}
              height={imageHeight}
              style={{
                height: imageHeight
              }}
              alt={locationName}
              className="mr-2"
            />
          )}
          <span
            className={`font-weight-bold ${small ? 'small' : ''}`}
          >
            {locationName}
          </span>
        </div>
      </div>
    );
  }

  return null;
};

export default OrgLocation;
