import { PLATFORM_NAME } from '../constants/globals';

const HEAP_ID = process.env.REACT_APP_HEAP_ID || 946566870;
const DEFAULT_SCRIPT_ID = 'reactjs-heap';

// https://developers.heap.io/reference/api-reference-overview

/**
 * Provides the Heap script to inject.
 */
const getHeapScript = (id) => `
  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("${id}");
`;

/**
 * Initializes Heap tracking.
 */
const initialize = (id = HEAP_ID) => {
  if (!id) {
    throw new Error('You need to provide your Heap Analytics ID.');
  }

  if (!document) {
    return;
  }

  const hasScript = Boolean(document.getElementById(DEFAULT_SCRIPT_ID));

  if (hasScript) {
    return;
  }

  const script = document.createElement('script');

  script.innerHTML = getHeapScript(id);
  script.id = DEFAULT_SCRIPT_ID;
  script.async = true;

  document.body.appendChild(script);
};

const identify = (userId) => {
  if (window.heap && userId) {
    window.heap.identify(userId);
  }
};

const addUserProperties = (params) => {
  if (window.heap && params) {
    window.heap.addUserProperties(params);
  }
};

const track = (eventName = '', params) => {
  if (window.heap) {
    const fullEventName = `${PLATFORM_NAME} - ${eventName}`;

    if (eventName && !params) {
      window.heap.track(fullEventName);
    }

    if (eventName && params) {
      window.heap.track(fullEventName, params);
    }
  }
};

/**
 * Object for manipulating Heap Analytics.
 */
const heapAnalytics = {
  initialize,
  identify,
  addUserProperties,
  track
};

export default heapAnalytics;
