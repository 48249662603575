import React, { useState } from 'react';
import {
  FaMicrophone,
  FaPause,
  FaStop,
  FaTrash
} from 'react-icons/fa';
import AudioReactRecorder, { RecordState } from '../AudioReactRecorder';
import Stopwatch from '../StopWatch';
import Audio from '../ManageContent/AudioFiles/Audio';

const AudioNotes = ({
  className,
  audioNotes,
  setAudioNotes,
  recordState,
  setRecordState
}) => {
  const recorderInitialState = recordState === RecordState.NONE || recordState === RecordState.STOP;
  const recorderRunningState = recordState === RecordState.START;
  const recorderPauseState = recordState === RecordState.PAUSE;
  // state to check stopwatch running or not
  const [isRunning, setIsRunning] = useState(false);

  const start = () => {
    setIsRunning(true);
    setRecordState(RecordState.START);
  };

  const pause = () => {
    setIsRunning(false);
    setRecordState(RecordState.PAUSE);
  };

  const stop = () => {
    setIsRunning(false);
    setRecordState(RecordState.STOP);
  };

  /**
   *
   * @param {*} audioData { blob, url, type, duration }
   *
   */
  const onStop = (audioData) => {
    setAudioNotes((prevState) => [...prevState, audioData]);
  };

  return (
    <div className={className}>
      <div
        className="d-flex flex-column align-items-center rounded mx-auto"
      >
        {Array.isArray(audioNotes) && audioNotes.length > 0 && (
          <div
            id="audio-container"
            className={`my-4 w-100 justify-content-center ${recordState === RecordState.STOP ? 'd-flex' : 'd-none'}`}
            style={{
              minHeight: '60px',
              rowGap: '8px'
            }}
          >
            {audioNotes.map((audioData, i) => {
              const url = URL.createObjectURL(audioData.blob);

              return (
                <div
                  key={`audio-recording-${i}`}
                  className='d-flex align-items-center'
                >
                  <Audio
                    id="vela-recording"
                    url={url}
                    type={audioData?.blob?.type}
                  />
                  <button
                    onClick={() => {
                      setAudioNotes([]);
                    }}
                    type='button'
                    className='btn btn-white btn-lg rounded ml-2 rounded-circle p-2'
                  >
                    <FaTrash className='text-ships-officer small' />
                  </button>
                </div>
              );
            })}
          </div>
        )}

        {Array.isArray(audioNotes) && audioNotes.length === 0 && (
          <div className='w-100'>
            <AudioReactRecorder
              state={recordState}
              onStop={onStop}
              canvasWidth="345px"
              canvasHeight={
                recorderRunningState || recorderPauseState ? '150px' : '0px'
              }
              backgroundColor="#121212"
              foregroundColor="#0b84e3"
              // type="audio/wav" // .wav
              // type="audio/ogg" // .oga
              type="audio/mpeg"
            />

            {(recorderRunningState || recorderPauseState) && (
              <div className="d-flex align-items-center justify-content-between">
                {recordState === RecordState.START && (
                  <button
                    type="button"
                    className="btn btn-lg btn-white p-2 rounded-circle ml-3 mb-4"
                    onClick={pause}
                  >
                    <FaPause />
                  </button>
                )}

                {recordState === RecordState.PAUSE && (
                  <button
                    type="button"
                    className="p-2 btn btn-lg btn-primary rounded-circle ml-3 mb-4"
                    onClick={start}
                  >
                    <FaMicrophone />
                  </button>
                )}

                <Stopwatch
                  simple
                  isRunning={isRunning}
                  setIsRunning={setIsRunning}
                  stop={stop}
                />

                {recordState === RecordState.NONE && (
                  <button
                    type="button"
                    className="p-2 btn btn-primary rounded-circle"
                    onClick={start}
                  >
                    <FaMicrophone />
                  </button>
                )}

                {/* <p className='mt-3 w-100 text-center h6'>
                  To preview audio, tap done.
                </p> */}

                <button
                  type="button"
                  className="p-2 btn btn-lg btn-danger rounded-circle mr-3 mb-4"
                  onClick={stop}
                >
                  <FaStop />
                </button>

              </div>
            )}

            {recorderInitialState && (
              <div
                className="d-flex flex-column flex-row justify-content-between align-items-center w-100 rounded mt-5"
              >

                <button
                  className="btn btn-lg btn-primary rounded-circle p-2 d-flex justify-content-center align-items-center"
                  type="button"
                  onClick={start}
                  style={{
                    height: '60px',
                    width: '60px',
                    boxShadow: '0px 0px 8px 8px rgba(255,255,255,.1)'
                  }}
                >
                  <FaMicrophone />
                </button>

                <p className='mt-4 w-100 text-center h6'>
                  Record Audio
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioNotes;
