import React from 'react';

const BodyCell = ({ value: body }) => {
  return (
    <div style={{ maxWidth: '300px' }}>
      { body || '' }
    </div>
  );
};

export default BodyCell;
