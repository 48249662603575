import React from 'react';

const NumberCell = ({ value }) => {
  const strValue = `${value}`;

  const num = strValue.includes('.') ? value.toFixed(2) : value;

  return (
    <div className='NumberCell d-flex align-items-center justify-content-center'>
      <span className='px-2 py-1 bg-light border rounded'>
        {num}
      </span>
    </div>
  );
};

export default NumberCell;
