// TODO: Move this component to component repo
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';
import './style.css';

const CardContent = ({
  imgUrl = '',
  imgPosition,
  title = '',
  mobileTitle = '',
  titleIconComponent,
  externalLink = false,
  subTitle = '',
  size = 'md'
}) => {
  const titleSize = size === 'md' ? 'h2' : 'h3';
  const objectPosition = useMemo(() => {
    if (imgPosition === 'center') {
      return 'object-position-center';
    }

    if (imgPosition === 'bottom') {
      return 'object-position-bottom';
    }

    return 'object-position-top';
  }, [imgPosition]);

  return (
    <>
      {imgUrl && (
        <img
          src={imgUrl}
          alt={title}
          className={`card-img-top border-bottom ${objectPosition}`}
        />
      )}

      <div
        className="card-body bg-white p-2 p-sm-3 h-100 w-100"
      >
        <div
          className={`font-weight-bolder ${titleSize} mb-0 card-title d-flex align-items-center justify-content-between`}
        >
          <div className='d-flex align-items-center'>
            {titleIconComponent && titleIconComponent()}
            <span className='d-none d-md-block'>
              {title}
            </span>
            <span className='d-md-none'>
              {mobileTitle || title}
            </span>
          </div>
          {externalLink && (
            <FaExternalLinkAlt className='opacity-50' style={{ marginTop: '-10px' }} size={18} />
          )}
        </div>
        {subTitle && (
          <div className="d-flex w-100 mt-1 mt-sm-2">
            <div
              className="card-text"
            >
              {subTitle}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const DashboardCard = ({
  href,
  onClickRoutePath = '',
  onClick,
  imgUrl = '',
  imgPosition,
  title = '',
  subTitle = '',
  mobileTitle = '',
  titleIconComponent,
  externalLink = false,
  className = ''
}) => {
  const classNames = useMemo(() => {
    return `DashboardCard card shadow border rounded overflow-hidden w-100 ${className}`;
  }, [className]);

  if (onClick) {
    return (
      <div
        className={`${classNames} cursor-pointer`}
        onClick={onClick}
        aria-hidden
      >
        <CardContent
          imgUrl={imgUrl}
          imgPosition={imgPosition}
          title={title}
          mobileTitle={mobileTitle}
          subTitle={subTitle}
          titleIconComponent={titleIconComponent}
        />
      </div>
    );
  }

  if (href) {
    return (
      <a
        href={href}
        className={classNames}
        title={title}
        aria-label={title}
        {...(externalLink ? { target: '_blank', rel: 'noreferrer' } : {})}
      >
        <CardContent
          externalLink={externalLink}
          imgUrl={imgUrl}
          imgPosition={imgPosition}
          title={title}
          subTitle={subTitle}
          titleIconComponent={titleIconComponent}
        />
      </a>
    );
  }

  return (
    <Link
      className={classNames}
      to={onClickRoutePath}
    >
      <CardContent
        imgUrl={imgUrl}
        imgPosition={imgPosition}
        title={title}
        subTitle={subTitle}
        titleIconComponent={titleIconComponent}
      />
    </Link>
  );
};

export default DashboardCard;
