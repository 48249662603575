import {
  SET_LOGBOOKS, RESET_LOGBOOKS
} from '../../actions/types';

const initialState = {
  list: null,
  fetched: false
};

export default function logbooks(state = initialState, action) {
  switch (action.type) {
    case SET_LOGBOOKS:
      return {
        ...state,
        list: action.list,
        fetched: true
      };
    case RESET_LOGBOOKS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
