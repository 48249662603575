import { isLocalhost } from '../serviceWorker';

export const FLAGSMITH_API_KEY_DEV = 'BYoJAfutfTzuZkRPpZc5LN';
export const FLAGSMITH_API_KEY = isLocalhost ? FLAGSMITH_API_KEY_DEV : process.env.REACT_APP_FLAGSMITH_API_KEY;
// Features
export const FLAG_APP_VELA = 'app_vela'; // Version
export const FLAG_MAINTENANCE_MODE = 'maintenance_mode';
export const FLAG_LOGBOOKS_READONLY = 'logbooks_readonly'; // not used in VELA
export const FLAG_LOGBOOKS_VELA_READONLY = 'logbooks_vela_readonly';
export const FLAG_USERS_READONLY = 'users_readonly';
// Features Text
export const NOTICE_MAINTENANCE_MODE_TITLE = 'Maintenance Mode';
export const NOTICE_MAINTENANCE_MODE_BODY = 'Routine maintenance will temporarily restrict access at this time.';

export const NOTICE_LOGBOOKS_READONLY_TITLE = 'Logbooks : Read-only';

export const NOTICE_USERS_READONLY_TITLE = 'User Account Maintenance';
export const NOTICE_ACCOUNT_CREATION_BODY = 'Routine maintenance will temporarily restrict account creation at this time.';
export const NOTICE_USERS_READONLY_BODY = 'Routine maintenance will temporarily restrict user account modifications at this time.';

export const FLAG_RESOURCES_READONLY = 'resources_readonly';
export const NOTICE_RESOURCES_READONLY_TITLE = 'Resources : Read-only';
