import dayjs from 'dayjs';
// import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';

// dayjs.extend(relativeTime);
dayjs.extend(timezone);

export const todaysDate = () => {
  return dayjs().format('MMM. D, YYYY', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const dateTimestamp = (customDate) => { // { utcHours }
  const date = customDate ? new Date(customDate) : new Date();
  const timezoneOffset = date.getMinutes() + date.getTimezoneOffset();
  const timestamp = date.getTime() + timezoneOffset * 1000;
  const correctDate = new Date(timestamp);

  // if (utcHours === true) {
  //   correctDate.setUTCHours(0, 0, 0, 0);
  // }

  return correctDate.toISOString();
};

// export const fromNowDate = () => {
//   dayjs.extend(relativeTime);

//   return dayjs().fromNow();
// }

export const mdyDate = (timestamp) => {
  return dayjs(timestamp).format('MMM. D, YYYY', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const mdDate = (timestamp) => {
  return dayjs(timestamp).format('MMM. D', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const dayMdyDate = (timestamp) => {
  return dayjs(timestamp).format('ddd., MMM. D, YYYY', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const mdySimple = (timestamp, separator = '/') => {
  return dayjs(timestamp).format(`MM${separator}DD${separator}YYYY`); // '01/25/2020'
};

export const ySimple = (timestamp) => {
  return dayjs(timestamp).format('YYYY'); // '2020'
};

export const hrMin = (timestamp) => {
  return dayjs(timestamp).format('h:mm A', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const parseUnix = (unixTimestamp) => {
  return dayjs.unix(unixTimestamp);
};

export const numDaysTillUnixDate = (unixDate) => {
  const today = dayjs();
  const numDays = parseUnix(unixDate).diff(today, 'day', true); // 7.645161290322581

  return numDays;
};

export const getGreeting = () => {
  const today = dayjs();
  const hour = Number(today.format('H'));

  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 18; // 24hr time to split the evening

  const isMorning = hour >= 5 && hour < splitAfternoon;
  const isAfternoon = splitAfternoon <= hour && hour < splitEvening;

  if (isMorning) {
    return 'Good morning';
  } if (isAfternoon) {
    return 'Good afternoon';
  }

  return 'Good evening';
};
