import { RESET_LOGBOOK_RECORDS, SET_LOGBOOK_RECORDS } from '../../actions/types';

const initialState = {
  list: null,
  pagination: null,
  fetched: false
};

export default function logbookRecords(state = initialState, action) {
  switch (action.type) {
    case SET_LOGBOOK_RECORDS: {
      return {
        ...state,
        list: action.logbookRecords,
        pagination: action.pagination,
        fetched: true
      };
    }
    case RESET_LOGBOOK_RECORDS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
