import sysState from './sysState';

const currentUserState = {
  name: '',
  email: '',
  orgId: '',
  courseIds: null, // string[]
  completedCourseIds: null, // string[]
  completedCourseTopicIds: null, // string[]
  locationId: '',
  uid: '',
  // PROFILE // TODO move to Firebase
  title: '',
  headline: '',
  phone: '',
  phoneExt: '',
  mobilePhone: '',
  aboutMe: '',
  // /PROFILE
  role: null, // string[]
  membership: null, // string[]
  onboarded: null, // boolean
  profilePicture: '', // string
  groupIds: null, // string[]
  calendarLink: '', // string
  supervisorIds: null, // string[]
  userMigrated: null, // boolean
  ...sysState
};

export default currentUserState;
