import React from 'react';
import Table from './Table';
import DateCell from './DateCell';
import NumberCell from '../../../ManageContent/Tables/NumberCell';
import TranscriptCell from './TranscriptCell';

const List = ({
  data,
  pagination,
  handleClickEdit
  // handleClickRow
}) => {
  const columns = React.useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
      handleClickEdit,
      Cell: DateCell
    },
    {
      Header: 'Transcript',
      accessor: 'transcript',
      disableSortBy: true,
      Cell: TranscriptCell
    },
    {
      Header: 'Hours',
      accessor: 'hours',
      Cell: NumberCell
    }
  ], [pagination, handleClickEdit]);

  if (!data || (data && data.length === 0)) {
    return (
      <div
        className="ColoredBox rounded shadow position-relative p-3 mb-3 my-auto d-flex justify-content-center align-items-center"
      >
        <div
          className="BoxText m-auto text-center h-100"
          style={{
            fontSize: '1rem'
          }}
        >
          No records.
        </div>
      </div>
    );
  }

  return (
    <Table
      handleClickEdit={handleClickEdit}
      columns={columns}
      data={data}
    />
  );
};

export default List;
