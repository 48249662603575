import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFlags } from 'flagsmith/react';
import { FaCloudUploadAlt, FaEllipsisH, FaPhotoVideo } from 'react-icons/fa';
import {
  FLAG_RESOURCES_READONLY,
  NOTICE_RESOURCES_READONLY_TITLE
} from '../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../actions/FeatureFlags';
import { deleteFile } from '../../../services/aws';
import { setCurrentModal } from '../../../actions/Modals';
import { deleteResource } from '../../../services/resources';
import { MODAL_KEY_UPLOAD_FILES } from '../../../constants/modals';
import { SUPABASE_CONTENT_TYPES } from '../../../constants/api';
import Modal from '../../Modal';
import CdnFilesList from './List';
import './style.css';

const CdnFiles = ({
  id,
  contentId,
  contentType,
  title,
  files,
  fileUpload,
  multiple = false,
  resourcePreview = false,
  editMenu = false,
  theme = 'dark', // light
  className = '', // TODO revisit this usage, go to Resources Groups Folder that has files
  header = true,
  integration = true, // better named variable
  removeFileCallback,
  hiddenColumns = ['parentId']
}) => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_RESOURCES_READONLY]);
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || '';
  // Local State
  const [removeItemDialogVisible, setRemoveItemDialogVisible] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);

  if (!currentUser.id) {
    return null;
  }

  const showRemoveItemDialog = (file) => {
    if (flags?.resources_readonly?.enabled && flags?.resources_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_RESOURCES_READONLY_TITLE
      }));
      return;
    }
    setRemoveItemDialogVisible(true);
    setItemToRemove(file);
  };

  const closeRemoveItemDialog = () => {
    setRemoveItemDialogVisible(false);
    setItemToRemove(null);
  };

  const handleRemoveSuccess = ({ name }) => {
    toast.dismiss('deletingFile');
    toast.success(`${name || 'File'} deleted!`);

    closeRemoveItemDialog();

    if (removeFileCallback && typeof removeFileCallback === 'function') {
      removeFileCallback();
    }
  };

  const handleRemove = () => {
    if (!itemToRemove) {
      return false;
    }

    const url = itemToRemove?.url || '';
    const name = itemToRemove?.name || '';
    const type = itemToRemove?.type || '';

    toast.info('Deleting file...', { toastId: 'deletingFile' });

    if (type === 'link') {
      deleteResource(itemToRemove?.id).then(() => {
        handleRemoveSuccess({ name });
      });

      return null;
    }

    deleteFile({ url, orgId }).then(() => {
      // Check if Supabase Content Type
      if (SUPABASE_CONTENT_TYPES.includes(contentType)) {
        deleteResource(itemToRemove?.id).then(() => {
          handleRemoveSuccess({ name });
        });
      }
    });
  };

  const showUploadFilesModal = () => { // TODO won't work for topicId or classId currently - why?
    dispatch(setCurrentModal({
      key: MODAL_KEY_UPLOAD_FILES,
      data: {
        contentType,
        contentId,
        integration,
        multiple
      }
    }));
  };

  const dialogConfirmRemoveItem = {
    visible: removeItemDialogVisible,
    title: `Delete ${itemToRemove ? itemToRemove.name : 'File'}?`,
    text: 'This action cannot be undone',
    primaryButton: {
      func: handleRemove,
      text: 'Yes'
    },
    secondaryButton: {
      func: () => {
        closeRemoveItemDialog();
      },
      text: 'No'
    },
    close: () => {
      closeRemoveItemDialog();
    }
  };

  /**
   * @summary - array of files stored on external CDN
   * @id {string} uuid created on turbine api (also used as filename in url)
   * @type {string} 'image/jpg', 'image/jpeg', 'image/png', 'application/pdf', 'application/vnd.ms-excel'
   * @name {string} 'cool-file.png'
   * @size {int} 301082
   * @url {string} 'https://turbine-user-content.s3.us-east-2.amazonaws.com/user-content/7bc6APXSkbx7hNHrVHljjm/1a120758-389e-43b2-be2a-78c736e814b9.jpg'
   */

  return (
    <>
      <div id={id || 'integration-files'} className="card overflow-content">
        {header && (
          <div
            className={`card-header d-flex align-items-center justify-content-between ${className} ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light'}`}
          >
            <h5 className="m-0">
              <span className='d-flex align-items-center'>
                <FaPhotoVideo />
                <span className='ml-1'>
                  {title || 'Files'}
                </span>
              </span>
            </h5>

            {fileUpload && editMenu && (
              <div className="btn-group align-items-center">
                <button
                  className={`btn btn-link btn-sm rounded ${theme === 'dark' ? 'text-white' : 'text-ships-officer'}`}
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  title="Manage Menu"
                  aria-label="Manage Menu"
                >
                  <FaEllipsisH />
                </button>
                <div className="dropdown-menu dropdown-menu-right p-0">
                  <button
                    className="dropdown-item py-2 border-bottom"
                    type="button"
                    title="Upload files"
                    onClick={showUploadFilesModal}
                  >
                    <span className='d-flex align-items-center'>
                      <FaCloudUploadAlt />
                      <span className='ml-1'>
                        Upload files
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        <div
          className="cdn-files card-body p-0"
          style={{
            minHeight: files ? '300px' : 'auto'
          }}
        >
          {files ? (
            <CdnFilesList
              data={files}
              contentId={contentId}
              contentType={contentType}
              deleteItem={showRemoveItemDialog}
              editMenu={editMenu}
              resourcePreview={resourcePreview}
              hiddenColumns={hiddenColumns}
            />
          ) : (
            <>
              {!fileUpload && (
                <div className="p-3">
                  Files will appear here when uploaded from fields above.
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {dialogConfirmRemoveItem.visible && (
        <Modal.Dialog {...dialogConfirmRemoveItem} />
      )}
    </>
  );
};

// {
//   "url": "https://turbine-user-content.s3.us-east-2.amazonaws.com/user-content/7bc6APXSkbx7hNHrVHljjm/37126d32-abd9-4849-9f2e-8fb0b39ee411.jpg",
//   "name": "portfolio--example-5.jpg",
//   "type": "image/jpeg",
//   "size": 301082
// }

export default CdnFiles;
