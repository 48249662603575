import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { withAuthorization } from '../components/Session';
import { DASHBOARD } from '../constants/routes';
import Loading from '../components/Loading';

const Slash = () => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const user = useSelector((state) => state.user);

  if ((user?.uid && !currentUser.id) || !organization?.id) {
    return (
      <Loading />
    );
  }

  if (!currentUser?.id || !organization?.id) {
    return <Loading />;
  }

  return (
    <Redirect to={DASHBOARD} />
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition)
)(Slash);
