import styled from 'styled-components';

export const Row = styled.div`
  background-color: #2f2f2f;
  border-radius: .5rem;
  color: white;
  text-decoration: none !important;
  min-height: 130px;
  font-size: 1.1rem;
`;

export const RowHeader = styled.div`
  background: rgba(255, 255, 255, .1);
  padding: .25rem .5rem;
  border-color: #5c5c5c !important;
  font-size: 1rem;
`;

export const RowFooter = styled.div`
  background: rgba(0, 0, 0, .1);
  border-color: #5c5c5c !important;
  font-size: .9rem;
`;

export const LocationName = styled.div`
  // background: rgba(0, 0, 0, .5);
  // padding: .25rem .5rem;
  font-size: .9rem;
`;

export const BoxText = styled.div`
  color: #e2e2e2;
  font-weight: bold;
  font-size: 1.25rem;
`;

export const BoxSubText = styled.div`
  color: #999999;
  // font-weight: bold;
  font-size: 1rem;
`;

export const RowFooterItem = styled.div`
  color: #999999;
  border-color: #5c5c5c !important;
  font-size: 1rem;
`;
