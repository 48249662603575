import formatUser from './formatUser';

function formatData(args, type, entry) {
  switch (type) {
    case 'user':
      return formatUser(args, entry);
    default:
      return false;
  }
}

export default formatData;
