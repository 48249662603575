import React from 'react';
import { formatHours } from '@apprentage/utils';
import TimeBadge from '../../components/TimeBadge';

const RecordContainer = ({
  hours = undefined,
  date = undefined,
  transcript = undefined,
  children,
  className = '',
  style = {}
}) => {
  return (
    <div
      className={`${className} ColoredBox rounded shadow position-relative p-3 d-flex justify-content-center align-items-center`}
      style={style}
    >
      <div className="d-flex align-items-center justify-content-between mb-3">
        {date !== undefined && (
          <div className='BoxSubText'>{date}</div>
        )}

        {hours !== undefined && (
          <TimeBadge duration={formatHours(hours ? hours.toFixed(2) : 0)} />
        )}
      </div>
      <div
        className="BoxText text-center"
        style={{
          fontSize: '1rem'
        }}
      >
        {transcript || children || null}
      </div>
    </div>
  );
};

export default RecordContainer;
