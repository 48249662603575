import React from 'react';

const MediaNotes = () => {
  return (
    <div>
      Media Notes
    </div>
  );
};

export default MediaNotes;
