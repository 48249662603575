const apprenticeshipState = {
  id: '',
  title: '',
  orgId: '',
  type: '',
  occupationName: '',
  instructionHours: '',
  ojtHours: '',
  startDate: '',
  expectedEndDate: '',
  rapidsCode: '',
  occupationONetCode: '', // TODO List all keys
  employerIds: null,
  courseIds: null,
  competencyNameSpace: '',
  classifications: null, // {}
  competencies: null, // {}
  competencyGroups: null, // []
  // XRWCJQVuhDXvWRW958EE?orgId=7bc6APXSkbx7hNHrVHljjm
  occupationTitle: '',
  occupationONetTitle: '',
  occupationONetDescription: '',
  // 4nDPEfld3VvFe3KCbrK1?orgId=3cGnNZMS3biPwCDMKoNAmu
  instructionProviderIds: null, // []
  createdAt: '',
  updatedAt: ''
};

export default apprenticeshipState;
