import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { isLocalhost } from '../serviceWorker';

// import monitorReducersEnhancer from './enhancers/monitorReducers'
// import loggerMiddleware from './middleware/logger'

import app from './reducers/app'; // import rootReducer from './reducers/index';
import apprenticeship from './reducers/apprenticeship';
import apprenticeships from './reducers/apprenticeships';
import currentUser from './reducers/currentUser';
import currentModal from './reducers/currentModal';
import locations from './reducers/locations';
import logbook from './reducers/logbook';
import logbooks from './reducers/logbooks';
import logbookHours from './reducers/logbookHours';
import logbookRecord from './reducers/logbookRecord';
import logbookRecords from './reducers/logbookRecords';
import material from './reducers/material';
import materials from './reducers/materials';
import organization from './reducers/organization';
import orgLocation from './reducers/orgLocation';
import sideSheet from './reducers/sideSheet';
import user from './reducers/user';
import userLocation from './reducers/userLocation';

const addPromiseSupport = (store) => {
  const rawDispatch = store.dispatch;
  return (action) => {
    if (typeof action.then === 'function') {
      return action.then(rawDispatch);
    }
    return rawDispatch(action);
  };
};

export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware]; // loggerMiddleware
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer]; // monitorReducersEnhancer
  const composedEnhancers = isLocalhost ? composeWithDevTools(...enhancers) : middlewareEnhancer;

  const rootReducer = combineReducers({
    app,
    apprenticeship,
    apprenticeships,
    currentUser,
    currentModal,
    locations,
    logbook,
    logbooks,
    logbookHours,
    logbookRecord,
    logbookRecords,
    material,
    materials,
    organization,
    orgLocation,
    sideSheet,
    user,
    userLocation
  });

  const store = createStore(rootReducer, preloadedState, composedEnhancers); // rootReducer

  store.dispatch = addPromiseSupport(store);

  return store;
}
