import React from 'react';
import { useSelector } from 'react-redux';
import {
  MODAL_KEY_FEATURE_FLAG_NOTICE, MODAL_KEY_RELOAD, MODAL_KEY_SEND_EMAIL, MODAL_KEY_UPLOAD_FILES
} from '../../constants/modals';
import SendEmail from './SendEmail';
import ManageRecord from './ManageRecord';
import FeatureFlagNotice from './FeatureFlagNotice';
import Reload from './Reload';
import UploadFiles from './UploadFiles';
import './style.css';

const Modals = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);

  if (!currentUser?.id || !currentModal?.key) {
    return null;
  }

  return (
    <>
      {currentModal?.key === MODAL_KEY_FEATURE_FLAG_NOTICE && (
        <FeatureFlagNotice />
      )}

      {currentModal?.key === MODAL_KEY_RELOAD && (
        <Reload />
      )}

      {currentModal.key === MODAL_KEY_UPLOAD_FILES && <UploadFiles />}

      {currentModal?.key === 'manageRecord' && (
        <ManageRecord />
      )}

      {currentModal?.key === MODAL_KEY_SEND_EMAIL && <SendEmail />}
    </>
  );
};

export default Modals;
