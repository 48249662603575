import { SET_MATERIAL_FILES } from './types';

/**
 *
 * @summary returns key to be used in dispatch event
 * @param {string} collection class, topic, entry (material, challenge, everything else)
 */
export const actionTypeFromParentType = ({ parentType }) => {
  // Construct Dispatch Data based on collection [class, topic, entry (everything else)]
  switch (parentType) {
    case 'materials':
      return SET_MATERIAL_FILES;
    default:
      return '';
  }
};
